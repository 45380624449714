<template>
    <div class="submenu">
        <div class="submenu-title"> {{ name }}</div>
        <div class="submenu-info">
            <div class="submenu-list"  :class="{ active : this.$store.state.menuActive1 }" @click="ParkIntroRouter">
                <span class="text">주차장 소개</span>
                <span class="list-arrow"> &#x276F;</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            name : "사업소개"
        }
    },
    methods: {
        ParkIntroRouter() {
            this.$router.push('/Parking').catch(()=>{});
            this.$store.state.menuActive1 = true;

        },
    }
}
</script>
<style lang="scss" scoped>
</style>