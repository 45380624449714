import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'

import Element from "element-ui";
import koLang from "element-ui/lib/locale/lang/ko";

// kendo ui
import "@progress/kendo-ui";
import "@progress/kendo-theme-default/dist/all.css";

import './styles/fonts.scss';
import './styles/base_styles.scss';
import './styles/comm_bar.scss';
import './styles/comm_card.scss';
import './styles/comm_button.scss';
import './styles/comm_form.scss';
import './styles/comm_styles.scss';
import './styles/comm_title_style.scss';
import './styles/css/Layout.css'

// naver map
import naver from "vue-naver-maps";

import {Dialog} from 'element-ui';
Vue.use(ElementUI);
Vue.use(Dialog);

Vue.config.productionTip = false

Vue.use(naver, {
  clientID: "i0x51o0k14",
  useGovAPI: true,
});

Vue.use(Element, {
  locale: koLang,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
