<template>
  <div>
    <el-dialog
      class="car-dialog"
      :visible="dialogVisibleSeasonTicketAgreeApert"
      :show-close="false"
      :close-on-click-modal="false"
      width="1350px"
    >
      <!-- 최상단 -->
      <div class="defaultProcessMain">
        <div class="defaultProcessDisplay">
          <div class="main-header left-section">
            <div class="rectangle-marker" />
            <div class="service-info">
              <div class="service-name">관악구 건물식공영주차장 월정기 사용동의서</div>
              <!-- <div class="service-description">
                선택된 상품에 대한 기본 정보를 안내합니다.
              </div> -->
            </div>
          </div>
        </div>

        <div class="button-container">
          <el-button class="back" @click="onClose">닫기</el-button>
          <el-button class="submit" @click="setSeasonTicketFormSubmitInfo">신청하기</el-button>
        </div>
      </div>

      <div class="line-marker" />

      <div class="detailParkingInfo">

        <div class="detailParkingMemberInfo-container">
          <div class="top-title">[신청정보]<span class="red right-title">( * 필수표기사항)</span></div>
          <div class="member-info-container">
            <div class="member-info-default-template">
              <div class="member-info-template">
                <div class="member-title">성명<span class="red">*</span></div>
                <el-input class="member-input name" v-model="memberInfo.memberName"/>
              </div>

              <div class="member-info-template between">
                <div class="member-title">생년월일<span class="red">*</span></div>
                <el-input class="member-input" type="number"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '')" placeholder="예) 19900101" v-model="memberInfo.memberBirthday"/>
              </div>

              <div class="member-info-template between">
                <div class="member-title">성별<span class="red">*</span></div>
                <el-radio-group class="member-input" v-model="memberInfo.memberSex">
                  <el-radio label="MALE">남성</el-radio>
                  <el-radio label="FEMAIL">여성</el-radio>
                </el-radio-group>
              </div>
            </div>


            <div class="member-info-template">
              <div class="member-title">주소<span class="red">*</span></div>
              <div class="address-template">
                <div class="address-container">
                  <el-input id="basicAddress" class="member-input address" disabled v-model="memberInfo.memberAddr" />
                  <el-button class="member-button" @click="search">검색</el-button>
                </div>
                <el-input class="member-input" placeholder="상세주소(선택)" v-model="memberInfo.memberDetailAddr"/>
              </div>

            </div>

            <div class="member-info-template">
              <div class="member-title">핸드폰<span class="red">*</span></div>
              <el-input class="member-input phone" type="number" placeholder="예) 01012345678"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '')" v-model="memberInfo.memberPhone"/>
            </div>
          </div>


          <div class="line" />

          <div class="top-title">[할인정보]</div>
          <div class="member-info-template">
            <el-radio-group class="member-input" @change="checkSeasonTicketDiscountInfo" v-model="memberInfo.memberDiscount">
              <el-radio :label="null">없음</el-radio>
              <el-radio label="모범납세자">모범납세자</el-radio>
              <el-radio label="상이,국가 유공자">상이,국가 유공자</el-radio>
              <el-radio label="장애">장애
                <el-input class="member-option" type="number"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '')" v-model="memberInfo.memberDiscountOption" />
                급</el-radio>
              <el-radio label="고엽제환자">고엽제환자</el-radio>
              <el-radio label="5.18 민주유공자">5.18 민주유공자</el-radio>
              <el-radio label="경차, 저공해">경차, 저공해</el-radio>
              <el-radio label="우수자원 봉사자">우수자원 봉사자</el-radio>
              <el-radio label="병역명문가">병역명문가</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 신청 및 사용 동의 내용 -->
        <div class="detailInfo-container">

          <div class="detailParkingInfo-container">

            <div class="main-title">[ 신청 및 사용 동의 내용 ]</div>

            <div class="content-container">
              <div class="content-first"> • 요 금 할 인</div>

              <div class="first-table">
                <div class="first-title-table-container">
                  <div class="first-title top">감면율</div>
                  <div class="first-title">감면대상</div>
                  <div class="first-title">비고</div>
                </div>

                <div class="first-content-table-container">

                  <div class="first-content-container top">
                    <div class="first-content-template parking">
                      <div class="first-content parking">100%</div>
                      <div class="first-content parking">80%</div>
                      <div class="first-content parking">50%</div>
                      <div class="first-content parking">30%</div>
                    </div>

                    <div class="first-content-template person">
                      <div class="first-content person">모범납세자(표지 발행일 기준 1년간)</div>
                      <div class="first-content person">장애인, 상이국가유공자, 고엽제후유증 환자</div>
                      <div class="first-content person">
                        <span>경형승용차(1,000cc미만), 5·18 민주항쟁유공자</span>
                        <span>저공해 차량(1~3종, 경유차 제외)</span>
                      </div>
                      <div class="first-content person">우수자원봉사자(구청장 발급), 병역명문가</div>
                    </div>

                    <div class="first-content-one">
                      <span>등록증(증서) 소지자에 한하며 요금감면 중복 시 감면율이 높은 하나만 적용</span>
                      <span>※감면대상자 본인 명의 차량에 한함(경차 제외)</span>
                      <span class="color">· 유효 기간이 명기된 증서에 대한 할인은 기간 만료 후 정상 요금 적용</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="text-info-container">
                <div class="text-info">• 신청서류: 월정기 신청서, 자동차등록증 사본, 요금감면 증빙자료</div>
                <div class="text-info">• 차량등록증전송주소: Email(pmparking@gwgd.or.kr) / FAX. 02-2081-2699 / 전화번호: 02-2081-2670</div>
                <div class="text-info color">※공용팩스로 분실·누락 및 개인정보 유출의 우려가 있습니다. 이에 따라 이메일 신청을 권장하며, 부득이한 팩스 신청의 경우 반드시 상기 전화번호로 확인하여 주시기를 바랍니다.</div>
              </div>

              <div class="info-container">
                <div class="content-first">① 월정기 주차이용은 운영시간에 한하며, 「서울특별시 관악구 주차장 설치 및 관리 조례」 등 관련 법규를 준수하여야 합니다.</div>

                <div class="content-first">② 차량 또는 신청자 정보 변경 시 공단에 반드시 통보하여야 하며, 통보하지 않으므로 발생되는 불이익은 신청자 책임입니다.</div>

                <div class="content-first">
                  <span>③ 월정기 운영기간은 반기(6개월)이며, 전산추첨을 통하여 배정하므로 기간 종료 후 추첨에서는 탈락 될 수 있습니다.</span>
                  <span>(※수시분 배정자는 해당 반기 정기분 사용기간 만료일까지만 사용가능)</span>
                </div>

                <div class="content-first">④ 불법주정차 과태료 3회 이상 체납 차량은 배정이 불가합니다. (※취소 및 정보변경으로 발생되는 환불금은 신고일 기준)</div>
                
                <div class="content-first">⑤ 월정기 주차요금은 사용 시작일 기준 5일 전까지 납부하여야 하며, 미납시에는 월정기 사용 취소 및 시간제 요금이 부과됩니다.</div>

                <div class="content-first">⑦ 월정기 이용중단 신청시 잔여기간에 대한 이용료는 신청일 기준으로 정산하여 환불됩니다.</div>

                <div class="content-first">⑧ 주차장 운영환경 변화(공사 및 계획)에 의해 월정기 주차는 이용중단 또는 주차구획은 없어질 수 있으며, 남은 기간에 대한 이용료는 사용종료일을 기준으로 정산 환불 처리되고, 별도의 주차 공간은 확보해 드리지 않습니다.</div>

                <div class="content-first">⑨ 주차구획 내 장애물 설치를 금지하며, 청결을 유지(오물 및 눈 등 사용자가 청소)하여 주시기 바랍니다.</div>

                <div class="content-first">⑩ 주차장 이용은 운영시간에만 사용 가능하며, 주차 이외의 목적으로 사용 시 배정은 취소 됩니다.</div>

                <div class="content-first">⑪ 주차(구획)장 사용 중 차량 훼손 및 도난은 사용자 본인의 책임으로, 관리주체는 어떠한 민·형사상의 책임을 지지 않습니다.</div>
                <div class="content-first">⑫ 주차장내에서는 주차관리원(공단직원)의 지시에 따르며, 불이행 시 배정취소 될 수 있습니다. </div>
                <div class="content-first color">⑬ 기타 주차환경 및 일반적인 주차장 운영에 방해가 되는 차량 및 사용자에 대해서는 배정이 취소될 수 있습니다.</div>
              </div>
            </div>

            <div class="check-parking-use-info">
              <div class="use-info">상기 신청 및 사용 동의 내용에 대하여 동의하고, 허위기재 또는 위반 등으로 인하여 발생되는 불이익에 대해서는   어떤 이의도 제기하지 않을 것을 서약하며 노상공영주차장 월정기 신청서를 제출합니다.</div>
              
              <el-radio-group class="check-radio" v-model="checkForm.submitFormCheck">
                <el-radio :label="true">동의합니다.</el-radio>
              </el-radio-group>
            </div>
          </div>


          <div class="info-template">

          <!-- <div class="personal-main-title">개인정보 수집 및 이용 동의서</div> -->
            <div class="personal-sub-title">
              <div class="sub-title">
              「개인정보보호법 및 관련 법령, 조례 등」에 따라 관악구시설관리공단 건물식공영주차장 월정기 이용과 관련하여 필요한 개인정보를 수집 및 이용하기 위한 동의 사항입니다.</div>
            </div>

            <!-- 1. 개인정보의 수집 및 이용 목적 -->
            <div class="personal-info-first-template">
              <div class="personal-title">1. 개인정보의 수집 및 이용 목적</div>

              <div class="personal-sub-title"> • 공영주차장 월정기 이용 및 요금 환불, 법령상 의무 이행 등</div>

              <div class="personal-table">
                <div class="personal-top">
                  <div class="personal-table-title">성명, 차량번호</div>
                  <div class="personal-table-content">주차장 이용, 법령상 의무 이행 등</div>
                </div>

                <div class="personal-bottom">
                  <div class="personal-table-title">연락처, 주소</div>
                  <div class="personal-table-content">고지서 및 SMS 발송, 주차권 발급 등 서비스 제공</div>
                </div>
              </div>
            </div>

            <!-- 2. 개인정보 수집 및 이용 항목 -->
            <div class="personal-info-second-template">
              <div class="personal-title">2. 개인정보 수집 및 이용 항목</div>

              <div class="personal-drop-title"> • 주차장 이용 관련 <span class="accent">필수적 정보</span></div>
              <div class="personal-drop-title">- 성명, 주소, 연락처, 차량번호, 생년월일</div>

              <div class="personal-drop-title"> • <span class="accent">필수적 정보</span>는 수집 및 이용에 동의 하셔아만 주차장 이용이 가능합니다.</div>

              <div class="personal-table">
                <div class="personal-top">
                  <div class="personal-table-title"> • 본인은 필수적 정보에 대한 개인정보를 수집⦁이용하는 것에 동의합니다.</div>
                  <!-- <div class="personal-table-content">주차장 이용, 법령상 의무 이행 등</div> -->
                </div>

                <div class="radio-check">
                  <el-radio-group class="radio-group" v-model="checkForm.essentialCheck">
                    <el-radio :label="true">동의함</el-radio>
                    <el-radio :label="false">동의하지 않음</el-radio>
                  </el-radio-group>
                </div>

              </div>
            </div>

            <!-- 3. 개인정보의 보유 및 이용기간 -->
            <div class="personal-info-second-template">
              <div class="personal-title">3. 개인정보의 보유 및 이용기간</div>

              <div class="personal-drop-title"> • 공단은 위의 정보를 신청자의 개인정보 수집 및 이용에 관한 동의일로부터 주차장</div>
              <div class="personal-drop-title">서비스 이용 종료시 까지 위의 이용목적을 위하여 보유 및 이용하며, 주차장 서비스</div>
              <div class="personal-drop-title">이용 종료 후에는 수집된 개인정보를 파기 처리합니다.</div>


              <div class="personal-table">
                <div class="personal-top">
                  <div class="personal-table-title"> • 본인은 주차장 서비스 이용 종료 후 수집된 개인정보를 파기하는데 동의합니다.</div>
                  <!-- <div class="personal-table-content">주차장 이용, 법령상 의무 이행 등</div> -->
                </div>

                <div class="radio-check">
                  <el-radio-group class="radio-group" v-model="checkForm.cancelCheck">
                    <el-radio :label="true">동의함</el-radio>
                    <el-radio :label="false">동의하지 않음</el-radio>
                  </el-radio-group>
                </div>

              </div>
            </div>

            <!-- 4. 제3자에 대한 개인정보 제공 안내 -->
            <div class="personal-info-third-template">
              <div class="personal-title">4. 제3자에 대한 개인정보 제공 안내</div>

              <div class="personal-sub-title"> • 공단은 아래와 같이 개인정보를 제3자에게 제공하고 있습니다.</div>

              <div class="personal-table">

                <div class="personal-first">
                  <div class="person-first-title top">개인정보 이용목적</div>
                  <div class="person-first-title content">주차장 이용, 법령상 의무 이행 등</div>
                </div>

                <div class="personal-second">
                  <div class="person-first-title top">제공하는 개인정보 항목</div>
                  <div class="person-first-title content">성명, 차량번호, 연락처등</div>
                  <div class="person-first-title">제공받는 자의 보유 기간</div>
                  <div class="person-first-title content">처리목적 달성 시까지</div>
                </div>

                <div class="personal-top">
                  <div class="personal-table-title"> • 본인은 제3자에 대한 개인정보 제공에 동의합니다.</div>
                </div>

                <div class="radio-check">
                  <el-radio-group class="radio-group" v-model="checkForm.acceptCheck">
                    <el-radio :label="true">동의함</el-radio>
                    <el-radio :label="false">동의하지 않음</el-radio>
                  </el-radio-group>
                </div>

              </div>

              <div class="footer-info">※ 개인정보 제3자 제공에 대해 거부할 수 있으며 거부 시 주차장 이용에 제한이 따를 수 있습니다.</div>
            </div>

            <div class="personal-footer-title"> &#128214; 관련 법규 등 변경에 따라 위의 내용 변경 시 공단 홈페이지를 등을 통해 안내하겠습니다.  &#128214;</div>
          </div>

        </div>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import build from "@/assets/api/axiosSetting";
// import publicBuild from "@/assets/api/publicSetting";

export default {
  components: {
  },
  mounted() {},
  props: {
    dialogVisibleSeasonTicketAgreeApert: {
      type: Boolean,
    },
    carIndex: {
      type: Number,
    },
    seasonIndex: {
      type: Number
    }
  },
  watch: {
    carIndex(val) {
      this.carId = val;
    },
    seasonIndex(val) {
      this.seasonId = val
    }
  },
  data() {
    return {
      carId: this.carIndex,
      seasonId: this.seasonIndex,
      checkForm: {
        submitFormCheck: null,
        essentialCheck: null,
        cancelCheck: null,
        acceptCheck: null
      },
      memberInfo: {
        memberName: null,
        memberBirthday: null,
        memberSex: null,
        memberAddr: null,
        memberDetailAddr: '',
        memberPhone: null,
        memberDiscount: null,
        memberDiscountOption: null
      }
    };
  },
  methods: {
    openPopup() {
      window.open('/popup', '_blank', 'width=850px, height=440px, top=50, left=50, scrollbars= 0, toolbar=0, menubar=no');
    },
    // 신청서 접수
    setSeasonTicketFormSubmitInfo() {

      const dateReg = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/
      const phoneReg = /^(01[016789]{1})?[0-9]{3,4}?[0-9]{4}$/;

      if (this.memberInfo.memberName === null || this.memberInfo.memberName === '') {
        alert('성명을 입력해주세요.')
      } else if (this.memberInfo.memberBirthday === null || this.memberInfo.memberBirthday === '') {
        alert('생년월일을 입력해주세요.')
      } else if (this.memberInfo.memberSex === null) {
        alert('성별을 선택해주세요.')
      } else if (document.getElementById('basicAddress').value === null || document.getElementById('basicAddress').value === '') {
        alert('주소를 입력해주세요.')
      }  else if (this.memberInfo.memberPhone === null || this.memberInfo.memberPhone === '') {
        alert('핸드폰번호를 입력해주세요.')
      } else if (this.checkForm.submitFormCheck !== true || this.checkForm.essentialCheck !== true || this.checkForm.cancelCheck !== true || this.checkForm.acceptCheck !== true) {
        alert('동의하지 않은 항목이 존재합니다.')
      } else if (this.memberInfo.memberDiscount === '장애' && (this.memberInfo.memberDiscountOption === null || this.memberInfo.memberDiscountOption === '')) {
        alert('급을 적어주세요.')
      } else if (dateReg.test(this.memberInfo.memberBirthday) === false) {
        alert('생년월일을 다시 확인해주세요.')
      } else if (phoneReg.test(this.memberInfo.memberPhone) === false) {
        alert('휴대폰번호를 다시 확인해주세요.')
      } else {

        if (this.memberInfo.memberDiscount === '장애') {
          this.memberInfo.memberDiscount = this.memberInfo.memberDiscount + ' ' + this.memberInfo.memberDiscountOption + '급'
        }

        const info = {
          name: this.memberInfo.memberName,
          birthday: this.memberInfo.memberBirthday,
          sex: this.memberInfo.memberSex,
          address: document.getElementById('basicAddress').value + ' ' + this.memberInfo.memberDetailAddr,
          phone: this.memberInfo.memberPhone,
          discountName: this.memberInfo.memberDiscount
        }

        build.patch("/cars/" + this.carId + "/register-season-ticket/season-products/" + this.seasonId, info)
        .then((res) => {
          alert("정기권 신청이 완료되었습니다.");

          this.openPopup()

          console.log(res);

          this.onClose();
        })
        .catch((err) => {
          console.log(err.response.data.message);

          alert(err.response.data.message);
        });
      }


    },
    // 신청서 할인 접수 확인
    checkSeasonTicketDiscountInfo() {
      if (this.memberInfo.memberDiscount !== '장애') {
        this.memberInfo.memberDiscountOption = null
      }
    },
    search() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          var roadAddr = data.roadAddress // 도로명 주소 변수
          var extraRoadAddr = '' // 참고 항목 변수

          // 법정동명이 있을 경우 추가한다. (법정리는 제외)
          // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
          if (data.bname !== '' && /[동|로|가]$/g.test(data.bname)) {
            extraRoadAddr += data.bname
          }
          // 건물명이 있고, 공동주택일 경우 추가한다.
          if (data.buildingName !== '' && data.apartment === 'Y') {
            extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName)
          }
          // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
          if (extraRoadAddr !== '') {
            extraRoadAddr = ' (' + extraRoadAddr + ')'
          }

          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          // document.getElementById('postcode').value = data.zonecode
          document.getElementById('basicAddress').value = roadAddr
        }
      }).open()
    },
    // 모달 닫기
    onClose() {
      this.checkForm.submitFormCheck = null
      this.checkForm.essentialCheck = null
      this.checkForm.cancelCheck = null
      this.checkForm.acceptCheck = null

      this.$parent.goBack()
      
      this.$emit("update:dialogVisibleSeasonTicketAgreeApert", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input::v-deep .el-input__inner {
  border-radius: 13px !important;
  box-shadow: 0px 1px 3px #11111112;
  font: 15px/21px NotoSansKR_Medium;
  color: #666666;
}

.el-select::v-deep .el-input__inner {
  border-radius: 13px !important;
  box-shadow: 0px 1px 3px #11111112;
  font: 15px/21px NotoSansKR_Medium;
  color: #666666;
}

.el-radio::v-deep .el-radio__label {
  font: 16px/24px NotoSansKR_Medium;
  color: #666666;
}

.el-input {
  ::v-deep {
    ::-webkit-inner-spin-button {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }
}

.detailParkingInfoImage {
  display: none;
}

.car-dialog {
  // 최상단
  .defaultProcessMain {
    display: flex;
    height: 50px;
    background: #fff 0 0 no-repeat padding-box;
    justify-content: space-between;
    padding: 0 0 0 20px;

    .left-section {
      display: flex;
    }

    .service-info {
      text-align: left;
      color: #333;
      position: relative;
      top: -4px;
      margin-left: 10px;

      .service-name {
        font: 40px/58px NotoSansKR_Bold;
      }

      .service-description {
        font: 20px/29px NotoSansKR_Medium;
      }
    }
    .back {
      height: 45px;
      width: 100px;
      background-color: #ccc;
      color: #fff;
      padding: 0 !important;
      margin-top: 2%;
      border-radius: 0;
    }

    .submit {
      height: 45px;
      width: 100px;
      background-color: #005aab;
      color: #fff;
      padding: 0 !important;
      margin-top: 2%;
      border-radius: 0;
    }
  }

  .line-marker {
    width: 98%;
    height: 2px;
    background-color: #707070;
    border: 0;
    margin: 3% 0 30px 25px;
  }

  .detailParkingInfo {
    width: 100%;
    // margin-left: 7%;
    display: flex;
    flex-wrap: wrap;

    .line-sub-marker {
      width: 100%;
      height: 2px;
      margin-left: 2%;
      margin-top: 1%;
      // margin-bottom: 1%;
      background-color: #707070;
      border: 0;
      margin: 0 0 10px 0;
    }
    .info-default-title {
      font: 20px/29px NotoSansKR_Medium;
      color: #333;
      // margin-left: 1%;
    }

    .top-title {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      margin-left: 2%;
      margin-top: 1%;
    }

    .right-title {
      // display: flex;
      margin-right: 2%;
      // justify-content: flex-end;
      // float: right;
    }
    
    .detailParkingMemberInfo-container {
      width: 98%;
      padding-bottom: 2%;
      margin-left: 2%;
      margin-bottom: 1%;
      border: 1px solid black;

      .red {
        color: #ff0000;
      }

      .line {
        height: 1px;
        background-color: black;
        margin-top: 2%;
      }

      .member-info-template {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .address-template {
          display: flex;
          flex-direction: column;
          width: 82.5%;

          .address-container {
            display: flex;
          }

          .address {
            width: 40%;
            margin-bottom: 10px;
          }

          .member-button {
            height: 40px;
            width: 100px;
            background-color: #005aab;
            color: #fff;
            padding: 0 !important;
            // margin-top: 2%;
            border-radius: 0;
            margin-left: 1%;
          }
        }

        .member-title {
          display: flex;
          align-items: center;
          width: 100px;
          font: 16px/24px NotoSansKR_Medium;
        }

        .member-input {
          width: 82.5%;
        }


      }

      .member-option {
        width: 80px;
        margin-left: 20px;
        margin-right: 10px;
      }

      .member-info-default-template {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

        .member-info-template {
          width: 30%;

          .member-input {
            width: 100%;
          }
          .name {
            width: 73%;
          }
        }
      }

      .between {
        margin-left: 20px;
      }
    }

    .detailInfo-container {
      width: 98%;
    }

    .detailParkingInfo-container {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 98.45%;
      margin-left: 2%;
      padding: 10px;
      border: 1px solid black;

      .main-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-family: NotoSansKR_Bold;
        padding: 10px 0;
      }

      .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .color {
          text-align: left;
          color: #D709C3;
        }

        .content-first {
          width: 90%;
          text-align: left;
          margin-bottom: 1%;
        }

        .first-table {
          width: 80%;
          .first-title-table-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid black;
            width: 100%;


            .first-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40%;
              height: 30px;
              border-left: 1px solid black;
              background-color: #ccc;
              color: #333;
            }
            .top {
              width: 20%;
              border-left: transparent;
            }
          }

          .first-content-table-container {
            width: 100.2%;
            .first-content-container {
              display: flex;
              // width: 100%;

              .first-content-template {
                width: 20.1%;
                
                .first-content {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  border: 1px solid black;
                  border-left: transparent;
                  border-top: transparent;
                  height: 65px;
                }

                .person {
                  width: 99.4%;
                }

                .parking {
                  border-left: 1px solid black;
                }
              }

              .person {
                width: 40.1%;
              }

              .time {
                width: 30.8%;
              }

              .first-content-one {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                text-align: left;
                width: 37.8%;
                padding: 10px;
                border: 1px solid black;
                border-left: transparent;
                border-top: transparent;
              }
            }

            .first-content-bottom-container {
              display: flex;
              .first-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 30%;
                height: 60px;
                border: 1px solid black;
                border-top: transparent;
                border-left: transparent;
              }

              .top {
                border-left: 1px solid black;
              }
            }
          }
        }

        .text-info-container {
          width: 80%;
          display: flex;
          flex-direction: column;
          margin-top: 1%;
          // justify-content: space-between;
        }

        .info-container {
          width: 78.5%;
          border: 1px solid black;
          margin: 10px 0;
          padding: 10px;

          .content-first {
            display: flex;
            flex-direction: column;
            text-align: left;
          }
        }
      }

      .check-parking-use-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .use-info {
          text-align: left;
          border: 1px solid black;
          padding: 10px;
          width: 78.5%;
          font-family: NotoSansKR_Bold;
        }

        .check-radio {
          width: 80%;
          text-align: right;
          margin-top: 1%;
        }
      }
    }

    .info-template {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      width: 98.45%;
      margin-left: 2%;
      padding: 10px;
      border: 1px solid black;
      margin-top: 0.5%;

      .personal-footer-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px 0;
        font-size: 24px;
        font-family: NotoSansKR_Bold;
        color: #333;
      }

      .personal-main-title {
        font-size: 30px;
        font-family: NotoSansKR_Bold;
        padding: 10px 0;
        color: #333;
      }

      .personal-sub-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .sub-title {
          text-align: left;
          font-size: 18px;
          width: 80%;

        }
      }

      .personal-info-first-template {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2%;

        
        .personal-title {
          width: 80%;
          text-align: left;

        }

        .personal-sub-title {
          display: flex;
          justify-content: left;
          width: 80%;
          text-align: left;
          margin-left: 2%;
          padding: 5px;
        }
        
        .personal-table {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .personal-top {
            display: flex;
            flex-direction: row;
            width: 80%;
            .personal-table-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30%;
              height: 30px;
              border: 1px solid black;
              background-color: #ccc;
              color: #333;
            }

            .personal-table-content {
              display: flex;
              align-items: center;
              justify-content: left;
              padding-left: 10px;
              width: 69%;
              height: 30px;
              border: 1px solid black;
              border-left: transparent;
            }
          }

          .personal-bottom {
            display: flex;
            flex-direction: row;
            width: 80%;
            .personal-table-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30%;
              height: 30px;
              border: 1px solid black;
              background-color: #ccc;
              color: #333;
              border-top: transparent;
            }

            .personal-table-content {
              display: flex;
              align-items: center;
              justify-content: left;
              padding-left: 10px;
              width: 69%;
              height: 30px;
              border: 1px solid black;
              border-left: transparent;
              border-top: transparent;
            }
          }
        }
      }

      .personal-info-second-template {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2%;

        .radio-check {
          width: 80%;
          margin-top: 1%;
          display: flex;
          justify-content: right;
        }
        
        .personal-title {
          width: 80%;
          text-align: left;
        }

        .personal-drop-title {
          width: 80%;
          text-align: left;
          margin-left: 4%;
          padding: 2px;
        }

        .personal-sub-title {
          width: 80%;
          text-align: left;
          margin-left: 2%;
          padding: 5px;
        }

        .personal-table {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .personal-top {
            display: flex;
            flex-direction: row;
            width: 80%;
            .personal-table-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 30px;
              border: 1px solid black;
              background-color: #ccc;
              color: #333;
            }

          }
        }
      }

      .personal-info-third-template {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2%;

        .radio-check {
          width: 80%;
          margin-top: 1%;
          display: flex;
          justify-content: right;
        }

        .personal-title {
          width: 80%;
          text-align: left;

        }

        .personal-sub-title {
          width: 80%;
          text-align: left;
          margin-left: 2%;
          display: flex;
          justify-content: left;
          padding: 5px;
        }

        .personal-table {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .personal-first {
            display: flex;
            flex-direction: row;
            width: 80%;

            .person-first-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              height: 30px;
              border: 1px solid black;
              border-left: transparent;
              border-bottom: transparent;
              background-color: #ccc;
              color: #333;
            }

            .content {
              background-color: white;
            }
            .top {
              border-left: 1px solid black;
            }
          }

          .personal-second {
            display: flex;
            flex-direction: row;
            width: 80%;

            .person-first-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 28%;
              height: 30px;
              border: 1px solid black;
              border-left: transparent;
              background-color: #ccc;
              color: #333;
            }

            .content {
              background-color: white;
            }

            .top {
              border-left: 1px solid black;
            }
          }

          .personal-top {
            display: flex;
            flex-direction: row;
            width: 80%;

            .personal-table-title {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 30px;
              border: 1px solid black;
              border-top: transparent;
              background-color: #ccc;
              color: #333;
            }
            // flex-direction: ;
          }
        }
      }

    }

  }
}

@media(max-width: 900px) {
  .car-dialog {
    ::v-deep {
      .el-dialog {
        width: 100vw !important;
      }
    }
    .defaultProcessMain  {
      .back {
        width: 85px;
      }
    }

    .defaultProcessMain {
      height: auto !important;
      flex-direction: column;
    }

    .line-marker {
      width: 93% !important;
    }

    .detailParkingInfo {
      width: 93%;
      .line-sub-marker {
        width: 100% !important;
      }
    }

    .service-info {
      .service-name {
        font-size: 22px !important;
      }

      .service-description {
        display: none;
      }
    }

    .detailInfo-container {
      overflow-x: scroll !important;
      width: 100% !important;
    }

    .detailParkingInfo-container {
      min-width: 1270px !important;
      flex-direction: column;
      // margin-left: 0.5% !important;

      .detailParkingInfoImage {
        display: block;
        width: 100% !important;

        .info-default-image {
          width: 100% !important;
          height: 300px !important;

          .image-none-text {
            width: 100% !important;
          }
        }
      }

      .detailParkingInfoLeft {
        margin: 0 !important;
      }

      .detailParkingInfoRight {
        display: none;
      }


    }
    
    .member-info-default-template {
      flex-direction: column;
      align-items: flex-start !important;
      

      .member-info-template {
        width: 97% !important;
        margin-left: 0 !important;

        .member-input {
          width: 82.5% !important;
          flex-direction: row !important;
        }


      }

      
      .member-input {
        display: flex;
        flex-direction: column;
      }



      .between {
        margin-left: 0 !important;
      }

      .name {
        width: 82.5% !important;
      }

    }

    


    .member-info-template {
      width: 90% !important;
      margin-left: 20px;
      // margin-left: 0 !important;

      .member-input {
        width: 82.5% !important;
        display: flex;
        flex-direction: column;
      }
    }

    .address-template {
      .member-input {
        width: 100% !important;
      }
    }


    .info-template  {
      min-width: 1270px !important;
      flex-direction: column;
      
    }

    .button-container {
      display: flex;
    }

  }
}
</style>
