<template>
    <div class="submenu">
        <div class="submenu-title"> {{ name }}</div>
        <div class="submenu-info">

            <div class="list-box">
                <div class="submenu-list" :class="{ active: this.$store.state.menuActive3 }" @click="PriceRouter">
                    <span class="text">주차요금 안내</span>
                    <span class="list-arrow"> &#x276F;</span>

                </div>
                <div class="submenu-menu">
                    <div class="list" :class="{ active: this.$store.state.menuActive1 }" @click="PriceRouter"><a>요금 안내</a></div>
                    <div class="list" :class="{ active: this.$store.state.menuActive2 }" @click="SaleRouter"><a>할인 안내</a></div>
                </div>
            </div>
            <div class="list-box">
                <div class="submenu-list"  :class="{ active: this.$store.state.submenuActive3 }" @click="ParkingRouter">
                    <span class="text">시설 현황</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
                <div class="submenu-menu">
                    <div class="list" :class="{ active: this.$store.state.submenuActive1 }" @click="ParkingRouter"><a>노상주차장 현황</a></div>
                    <div class="list" :class="{ active: this.$store.state.submenuActive2 }" @click="ParkingRouter2"><a>노외주차장 현황</a></div>
                </div>
            </div>
            <div class="list-box">
                <div class="submenu-list"  :class="{ active: this.$store.state.AmenuActive }" @click="IntroRouter">
                    <span class="text">주차장 안내</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
                </div>

                <div class="list-box">

                </div>

                <div class="list-box">
                <div class="submenu-list"  :class="{ active: this.$store.state.BmenuActive }" @click="RandomPassRouter">
                    <span class="text">정기권 신청</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
                <!-- <div class="submenu-menu season"> -->
                    <!-- <div class="list" :class="{ active: this.$store.state.submenuActive7 }" @click="PassRouter"><a>정기권 선착순 신청</a></div> -->
                    <!-- <div class="list" :class="{ active: this.$store.state.submenuActive8 }" @click="RandomPassRouter"><a>정기권 추첨형 신청</a></div>
                </div> -->

                </div>

            <div class="list-box" v-if="this.$store.state.isLogin">
                <div class="submenu-list"  :class="{ active: this.$store.state.unpaidActive }" @click="unpaidRouter">
                    <span class="text">미납 조회</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            name: "이용안내"
        }
    },
    methods: {
        PriceRouter() {
            this.$router.push('/Price').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = true;
        },
        SaleRouter() {
            this.$router.push('/Sale').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.menuActive3 = true;
        },
        ParkingRouter() {
            this.$router.push('/InParking').catch(()=>{});
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = true;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        ParkingRouter2() {
            this.$router.push('/OutParking').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = true;
            this.$store.state.submenuActive3 = true;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        IntroRouter() {
            this.$router.push('/IntroParking').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = true;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;

        },
        PassRouter() {
            this.$router.push('/Pass').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = true;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = true;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        RandomPassRouter() {
            this.$router.push('/Randompass').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = true;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = true;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        unpaidRouter() {
            const m2 = document.querySelector('.m2')
            m2.classList.remove('active')
            this.$router.push('/unpaidPass').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = true
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        }
    }
}
</script>
<style lang="scss" scoped>

.season {
    height: 50px;
}
</style>