<template>
  <div class="mobileMenuBar">
    <select class="myPageMenu"
    v-model="menuValue"
    @change="menuRouter">
      <option
      v-for="(menu, index) in myPageMenuList"
      :key="index"
      :value="menu.value">{{ menu.name }}</option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    menuCheck: {
      type: String
    }
  },
  watch: {
    menuCheck(val) {
      this.menuValue = val
    }
  },
  data() {
    return {
      myPageMenuList: [
        { name: '차량 등록 관리', value: 'main' },
        { name: '차량 할인 관리', value: 'carDiscount' },
        { name: '정기권 사용 내역', value: 'seasonUse' },
        { name: '정기권 신청 내역', value: 'seasonCheck' },
        { name: '정기권 구매 내역', value: 'seasonPayment' },
        { name: '회원 탈퇴', value: 'outUser' }
      ],
      menuValue: this.$store.state.mypage.menuCheck
    }
  },
  mounted() {
    // this.$router.go(this.$router.currentRoute)

    if (this.$route.query.select !== null) {
      this.$store.state.mypage.menuCheck = this.$route.query.select
      this.menuValue = this.$route.query.select
      this.menuRouter()
    } else if (this.$store.state.mypage.menuCheck !== null) {
      this.menuValue = this.$store.state.mypage.menuCheck
    }

    
  },
  methods: {
    menuRouter() {

      this.$store.state.mypage.menuCheck = this.menuValue

      console.log('menuValue', this.$store.state.mypage.menuCheck);

      if (this.menuValue === 'main') {
        
        this.$router.push({ name: '차량등록현황', query: { select: this.menuValue } }).catch(()=>{});
        
      } else if (this.menuValue === 'carDiscount') {

        this.$router.push({ name: '할인등록현황', query: { select: this.menuValue } }).catch(()=>{});
        
      } else if (this.menuValue === 'seasonUse') {

        this.$router.push({ name: '정기권사용내역', query: { select: this.menuValue } }).catch(()=>{});
        
      } else if (this.menuValue === 'seasonCheck') {

        this.$router.push({ name: '정기권신청내역', query: { select: this.menuValue } }).catch(()=>{});
        
      } else if (this.menuValue === 'seasonPayment') {

        this.$router.push({ name: '정기권구매내역', query: { select: this.menuValue } }).catch(()=>{});
        
      } else if (this.menuValue === 'outUser') {

        this.$router.push({ name: '회원 탈퇴', query: { select: this.menuValue } }).catch(()=>{});
        
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.myPageMenu {
  width: 60%;
  height: 42px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid #cccccc;
}

</style>