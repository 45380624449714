<template>

    <div class="inner">
        <div class="SubMenuWrapper">
            <SubMenuBarM4 />
        </div>

        <div class="PageContentsWrapper">
            <MobileMenuBar
            class="MobileMenuBar"
            :menuCheck="this.$store.state.mypage.menuCheck" />

            <router-view />
        </div>

    </div>
</template>
<script>
import MobileMenuBar from './mobileMenu/MobileMenuBar.vue';
import SubMenuBarM4 from './SubMenuBar.vue';

export default {
    components: {
        SubMenuBarM4,
        MobileMenuBar
    },
    data() {
        return {
            menu: 'main'
        }
    }
}
</script>
<style lang="scss" scoped>

    .MobileMenuBar {
        display: none;
    }
    .inner {
        display: flex;
        position: relative;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        justify-content: flex-end;
        .SubMenuWrapper {
            width: 288px;
            position: absolute;
            left: 0;
            top: -110px;
            height: 100%;
        }
        .PageContentsWrapper {
            box-sizing: border-box;
            padding-top: 74px;
            width: calc(100% - 318px);
            height: 100%;
            padding-bottom: 20px;
            min-height: calc(100vh - 100px - 60px);
        }
    }

    @media (max-width: 900px) {

        .PageContentsWrapper {
            width: 94% !important;
            margin-right: 3%;
            padding-top: 50px !important;

            .MobileMenuBar {
                display: block;
                width: 100%;
            }
        }
        .SubMenuWrapper {
            display: none !important;
        }
    }
</style>