<template>
    <div class="footer">
        <img src="../../assets/icons/icon_title_logo.png" alt="logo" class="logo">
        <div class="footer-info">
            <div class="info-1 info">
                <span class="click">찾아오시는길 &#183;</span>  
                <ul>
                    <li>개인정보처리방침</li>
                    <li>이용약관</li>
                    <li>이메일무단수집거부</li>
                </ul>
            </div>
            <div class="info-2 info">
                서울특별시 관악구 남부순환로 1369 
                <!-- <br class="br" /> 관악농협농산물백화점 5층 2호 -->
            </div>
            <div class="info-3 info">
                대표번호: 02-2081-2600
            </div>
            <div class="info-4 info">
                Copyright(c) 2022 by 관악구 시설관리 공단. ALL Right Reserved.
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>

<style lang="scss" scoped>
    .footer {
        margin: auto;
        max-width: 1200px;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        .click {
            cursor: pointer;
            margin-right: 5px;
        }
        .footer-info {
            width: 100%;
            font-size: 14px;
            text-align: left;
            margin-left: 45px;
            color: #4C4C4C;
            font-family: 'NotoSansKR_Regular';
            .info {
                margin: 5px 0;

                .br {
                    display: none;
                }
            }
            .info-1 {
                font-size: 16px;
                display: flex;
                ul {
                    cursor: pointer;
                    margin-left: 5px;
                    color: #0079C2;
                    display: flex;
                    li::after {
                        content: "|";
                        margin: 0 5px;
                    }
                    li:last-child::after {
                        content: "";
                    }
                }
            }
        }
    }

    @media (max-width: 1300px) {
        .footer {
            max-width: 98vw !important;
            width: 100vw !important;

            .logo {
                width: 43vw !important;
            }

            .footer-info {
                width: 45vw !important;
                font-size: 13px;
                .info-1 {
                    font-size: 14px;
                }
            }
        }
    }

    @media(max-width: 900px) {

        .footer {

            .logo {
                // width: 38vw !important;
                display: none;
            }
            .footer-info {
                width: 100% !important;
                margin-left: 0 !important;
                font-size: 12px !important;

                .info {
                    text-align: center;
                }

                .info-1 {
                    font-size: 13px;
                    align-items: center;
                    justify-content: center;

                    // ul {
                    //     flex-direction: column;
                    // }
                }
            }
        }
        
    }

    @media(max-width: 400px) {

        .footer {
            .footer-info {

                .info-1 {
                    flex-direction: column;
                }
                .info {
                    .br {
                        display: block;
                    }
                }
            }
        }

    }
</style>