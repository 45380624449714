<template>
    <div class="MainContainer"
    @mousedown="down" @touchstart.stop="down"
    @mousemove="move" @touchmove.stop="move"
    @mouseup="end" @touchend.stop="end">
        <div class="NavWrapper">
            <NavBar />
        </div>
        <div class="MenuWrapper">
            <MenuBar class="MenuBar" />
            <MediaNarBar class="MediaNarBar" />
        </div>
        <div class="ImgWrapper">
            <img src="@/assets/main.jpg" alt="img">
        </div>

        <div class="ContentsWrapper">
            <PageInnerM2 />
        </div>
        <div class="FooterWrapper">
            <FooterBar />
        </div>

    </div>
</template>

<script>

import MenuBar from '@/views/static/MenuBar.vue'
import FooterBar from '@/views/static/FooterBar.vue'
import NavBar from '@/views/static/NavBar.vue';
import PageInnerM2 from './PageInner.vue';
import MediaNarBar from '@/views/static/MediaNarBar.vue';

export default {
    components: {
        NavBar,
        FooterBar,
        MenuBar,
        PageInnerM2,
        MediaNarBar
    },
    data() {
        return {
            startEvent: false,
            moveEvent: false,
            flags: false,
            dx: 0,
            dy: 0,
            position: {
                x: 320,
                y: 60
            },
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            mobileTouch: true,
            checkMobile: false
        }
    },
    mounted() {
        this.isMobile();

        window.addEventListener('touchmove', () => {
            this.mobileTouch = false;
        });

        window.addEventListener('touchstart', () => {
            this.mobileTouch = true;
        });

        window.addEventListener('touchcancel', () => {
            this.mobileTouch = true;
        });

        window.addEventListener('touchend', () => {
            this.mobileTouch = true;
        });

    },
    unmounted() {

        window.removeEventListener('touchmove', () => {});
        window.removeEventListener('touchstart', () => {});
        window.removeEventListener('touchcancel', () => {});
        window.removeEventListener('touchend', () => {});

    },
    methods: {
        down(event){

            this.flags = true;

            var touch;

            if(event.touches){
                touch = event.touches[0];
            }else {
                touch = event;
            }

            this.dx = touch.clientX - event.target.offsetLeft
            this.dy = touch.clientY - event.target.offsetTop

        },
        move(event) {

            if(this.flags) {
                var touch;

                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }

                this.position.x = touch.clientX - this.dx;
                this.position.y = touch.clientY - this.dy;

                if (this.position.x < 0) {
                    this.position.x = 0
                } 
                else if (this.position.x > this.screenWidth - touch.target.clientWidth) {
                    this.position.x = this.screenWidth - touch.target.clientWidth
                }

                if (this.position.y < 0) {
                    this.position.y = 0
                } else if (this.position.y > this.screenHeight - touch.target.clientHeight) {
                    this.position.y = this.screenHeight - touch.target.clientHeight
                }

                document.addEventListener("touchmove",(ev) => {
                    ev.preventDefault();
                },false);
            }
        },
        end() {
            this.flags = false;
        },
        isMobile() {
            var user = navigator.userAgent;

            // console.log(user)

            if( user.indexOf("iPhone") > -1 
                || user.indexOf("Android") > -1 
                || user.indexOf("iPad") > -1
                || user.indexOf("iPod") > -1
            ) {
                this.checkMobile = true;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.MediaNarBar {
    display: none;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

    .MainContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .NavWrapper {
        width: 100%;
        height: 60px;
        background-color: #0079C2;
    }
    .MenuWrapper {
        width: 100%;
        height: 100px;
        box-shadow: 0.1px 2px 6px rgb(0, 0, 0, 0.3);
    }
    .ImgWrapper {
        width: 100%;
        height: 140px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .FooterWrapper {
        width: 100%;
        height: 168px;
        background-color: #F9F9F9;
    }
    .ContentsWrapper {
        height: 100%;
        width: 100%;
        display: flex;
    }

    @media (max-width: 900px) {

        .NavWrapper {
            display: none;
        }
        .MenuWrapper {
            
            position: fixed;
            top: 10px;
            z-index: 120;
            box-shadow: none;
        }

        .MainContainer {
            width: 100vw !important;
        }
        .MenuBar {
            display: none;
        }

        .MediaNarBar {
            display: block;
        }
    }
</style>