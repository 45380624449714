import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mypage: {
      menuCheck: "main",
    },
    // 로그인
    isLogin: Boolean(localStorage.getItem("accessTokenUser")),
    // 관리자 여부
    isAdmin: false,
    userName: null,

    // 차량등록현황
    menuActive1: false,
    // 정기권 사용내역
    menuActive2: false,
    // 정기권 신청내역
    menuActive3: false,
    // 정기권 등록 현황
    submenuActive1: false,
    // 회원정보 수정
    submenuActive2: false,
    // 회원탈퇴
    submenuActive3: false,
    // 차량 등록 관리
    submenuActive4: false,
    // 할인 등록 관리
    submenuActive5: false,
    // 정기권 구매 내역
    submenuActive6: false,

    // 정기권 선착순 신청
    submenuActive7: false,
    // 정기권 추첨형 신청
    submenuActive8: false,

    AmenuActive: false,
    BmenuActive: false,
    PActive: false,
    // 미납 조회
    unpaidActive: false,
    lineActive1: false,
    lineActive2: false,
    lineActive3: false,

    overActive1: false,
    overActive2: false,
    overActive3: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
