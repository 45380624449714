<template>
  <div class="menu-container">

    <div class="menu">
      <div class="menu-box">
          <div id="menu-open-icon" class="menu-icon">&#8803;</div>
          <img src="../../assets/icons/icon_title_logo.png" alt="logo" @click="MainRouter" class="logo">
      </div>

    </div>
  
    <div id="media-menu-wrapper" class="media-menu-wrapper">
      <div class="media-menu-header-container">

        <div class="media-menu-header">
          <img src="../../assets/icons/icon_title_mobile_logo.png" alt="logo" @click="MainRouter" class="media-logo">
          <span class="close" @click="closeSubMenu">닫기</span>
        </div>

        <div class="member-menu">
          <!-- <span class="memberText" v-if="!this.$store.state.isLogin" @click="checkLogin">확인</span>
          <span class="bar" v-if="!this.$store.state.isLogin">|</span> -->
          <span class="memberText" v-if="!this.$store.state.isLogin" @click="LoginRouter">로그인</span>
          <span class="bar" v-if="!this.$store.state.isLogin">|</span>
          <span class="memberText" v-if="!this.$store.state.isLogin" @click="RegisterRouter">회원가입</span>

          <span class="memberText" v-if="this.$store.state.isLogin" @click="myPageRouter">마이페이지</span>
          <span class="bar" v-if="this.$store.state.isLogin">|</span>
          <span class="memberText" v-if="this.$store.state.isLogin" @click="logOutRouter">로그아웃</span>
        </div>
      </div>

      <ul class="gnb">
          <li class="m1 men" :class="{ lineactive :  this.$store.state.lineActive1 , active : this.$store.state.overActive1}"  >
              <a id="intro" class="pointer" @click="openDropMenu">사업소개<span class="marker">&#9002;</span></a>
              <div class="main-line" />
              <ul id="parkingInfo" class="subm1 subm">
                  <li class="m1-1 pointer" @click="ParkIntro"><span class="box-marker">&#9608;</span>주차장 소개</li>
                  <div class="dropdown-line" />
              </ul>
          </li>
          <li class="m2 men" :class="{ lineactive :  this.$store.state.lineActive2 , active : this.$store.state.overActive2}" >
              <a id="guide" class="pointer" @click="openDropMenu">이용안내<span class="marker">&#9002;</span></a>
              <div class="main-line" />
              <ul id="useInfo" class="subm2 subm">
                  <li class="m1-1"><span class="box-marker">&#9608;</span>주차요금 안내</li>
                  <div class="dropdown-line" />

                  <ul class="sub-dropdown">
                    <li class="pointer" @click="PriceRouter"><span class="box-marker">&#9608;</span>요금 안내</li>
                    <div class="dropdown-box-line" />
                    <li class="pointer" @click="SaleRouter"><span class="box-marker">&#9608;</span>할인 안내</li>
                    <div class="dropdown-box-line" />
                  </ul>

                  <li class="m1-1"><span class="box-marker">&#9608;</span>시설 현황</li>
                  <div class="dropdown-line" />

                  <ul class="sub-dropdown">
                    <li class="pointer" @click="ParkingRouter"><span class="box-marker">&#9608;</span>노상주차장 현황</li>
                    <div class="dropdown-box-line" />
                    <li class="pointer" @click="ParkingRouter2"><span class="box-marker">&#9608;</span>노외주차장 현황</li>
                    <div class="dropdown-box-line" />
                  </ul>

                  <li class="m1-1 pointer" @click="IntroRouter"><span class="box-marker">&#9608;</span>주차장 안내</li>
                  <div class="dropdown-line" />
                  <li class="m1-1"><span class="box-marker">&#9608;</span>정기권 신청</li>
                  <div class="dropdown-line" />

                  <ul class="sub-dropdown">
                    <!-- <li class="pointer" @click="PassRouter"><span class="box-marker">&#9608;</span>정기권 선착순 신청</li>
                    <div class="dropdown-box-line" /> -->
                    <li class="pointer" @click="RandomRouter"><span class="box-marker">&#9608;</span>정기권 추첨형 신청</li>
                    <div class="dropdown-box-line" />
                  </ul>

                  <li class="m1-1 pointer" v-if="this.$store.state.isLogin" @click="unpaidRouter"><span class="box-marker">&#9608;</span>미납 조회</li>
                  <!-- <div class="dropdown-line" /> -->
              </ul>
          </li>
          <li class="m3 men" :class="{ lineactive :  this.$store.state.lineActive3 }" >
              <a id="notice" class="pointer" @click="openDropMenu">고객지원<span class="marker">&#9002;</span></a>
              <div class="main-line" />
              <ul id="noticeInfo" class="subm3 subm">
                  <li class="m1-1 pointer" @click="NoticeRouter"><span class="box-marker">&#9608;</span>공지 사항</li>
                  <div class="dropdown-line" />
                  <li class="m1-1 pointer" @click="faqRouter"><span class="box-marker">&#9608;</span>자주하는 질문</li>
                  <div class="dropdown-line" />
                  <li class="m1-1 pointer" @click="ServiceRouter"><span class="box-marker">&#9608;</span>문의 사항</li>
                  <div class="dropdown-line" />
              </ul>
          </li>
      </ul>

    </div>
  </div>
</template>

<script>
import build from '@/assets/api/axiosSetting'

export default {
  mounted() {

    this.openSubMenu()
  },
  data() {
    return {
      msov: false,
    }
  },
  methods: {
    openSubMenu() {
      const mainMenu = document.getElementById('menu-open-icon')

      mainMenu.addEventListener('click', () => {

        const showMenu = document.getElementById('media-menu-wrapper')

        console.log(showMenu.style.display);
        if (showMenu.style.display === '' || showMenu.style.display === 'none') {
          showMenu.style.display = 'block'
        } else {
          showMenu.style.display = 'none'
        }

      })

    },
    checkLogin() {
      build.post('/login',
        {
          username: "user1",
          password: "user1", 
        }
      ).then(res => {
        // console.log('checkLogin res', res);
        this.$store.state.isLogin = true;
        
        localStorage.setItem('accessTokenUser', res.data.body.accessToken)
        localStorage.setItem('accessTokenUserIndex', res.data.body.userId)

        this.$store.state.userName = res.data.body.name
      }).catch(err => {
          console.log('checkLogin error',err);
      })
    },
    LoginRouter() {
        window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/126'
    },
    RegisterRouter() {
      window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/127'

    },
    openDropMenu(ev) {

      const parkingInfo = document.getElementById('parkingInfo') 
      const useInfo = document.getElementById('useInfo')
      const noticeInfo = document.getElementById('noticeInfo')


      if (ev.target.id === 'intro') {

        if (parkingInfo.style.display === '' || parkingInfo.style.display === 'none') {
          parkingInfo.style.display = 'block'
          useInfo.style.display = 'none'
          noticeInfo.style.display = 'none'
        } else {
          parkingInfo.style.display = 'none'
        }
      } else if (ev.target.id === 'guide') {

        if (useInfo.style.display === '' || useInfo.style.display === 'none') {
          useInfo.style.display = 'block'
          parkingInfo.style.display = 'none'
          noticeInfo.style.display = 'none'
        } else {
          useInfo.style.display = 'none'
        }
      } else if (ev.target.id === 'notice') {
        
        if (noticeInfo.style.display === '' || noticeInfo.style.display === 'none') {
          noticeInfo.style.display = 'block'
          parkingInfo.style.display = 'none'
          useInfo.style.display = 'none'
        } else {
          noticeInfo.style.display = 'none'
        }
      }

    },
    closeSubMenu() {
      const showMenu = document.getElementById('media-menu-wrapper')

      showMenu.style.display = 'none'
    },
    loadingAlert() {
      alert('서비스 준비중입니다.')
    },
    // 메인페이지
    MainRouter() {
      this.$router.push('/MainPage').catch(() => { });

      this.closeSubMenu()
    },
    // 주차장소개
    ParkIntro() {
      this.$router.push('/Parking').catch(() => { });

      this.closeSubMenu()
    },
    // 공지사항
    NoticeRouter() {
      this.$router.push('/Notice').catch(() => { });

      this.closeSubMenu()
    },
    // 자주하는질문
    faqRouter() {
      this.$router.push('/FAQ').catch(() => { });

      this.closeSubMenu()
    },
    // 문의사항
    ServiceRouter() {
      this.$router.push('/Service').catch(() => { });

      this.closeSubMenu()
    },
    // 요금안내
    PriceRouter() {
      this.$router.push('/Price').catch(() => { });

      this.closeSubMenu()

    },
    // 할인안내
    SaleRouter() {
      this.$router.push('/Sale').catch(() => { });

      this.closeSubMenu()
    },
    // 노상주차장
    ParkingRouter() {
      this.$router.push('/InParking').catch(() => { });

      this.closeSubMenu()
    },
    // 노외주차장
    ParkingRouter2() {
      this.$router.push('/OutParking').catch(() => { });

      this.closeSubMenu()
    },
    // 주차장 안내
    IntroRouter() {
      this.$router.push('/IntroParking').catch(() => { });

      this.closeSubMenu()
    },
    // 선착순정기권
    PassRouter() {
      this.$router.push('/Pass').catch(() => { });

      this.closeSubMenu()
    },
    RandomRouter() {
      this.$router.push('/Randompass').catch(() => { });

      this.closeSubMenu()
    },
    // 미납조회
    unpaidRouter() {
      this.$router.push('/unpaidPass').catch(() => { });

      this.closeSubMenu()
    },
    // 마이페이지
    myPageRouter() {
      this.$router.push({ name: '차량등록현황', query: { select: 'main' } })

      this.closeSubMenu()
    },
    deleteCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
    // 로그아웃
    logOutRouter() {
      localStorage.removeItem('accessTokenUser')
      localStorage.removeItem('accessTokenUserIndex')

      // this.deleteCookie('popupCheck')

      this.$store.state.isLogin = false

      this.$router.push({name: '메인페이지'})
    }
  }
}
</script>

<style lang="scss" scoped>

.menu {
  display: flex;
  width: 100%;
  position: fixed;
  justify-content: center;
  box-shadow: 0.1px 2px 6px rgb(0, 0, 0, 0.3);
  top: 10px;
}

.menu-box {
  display: flex;
  background-color: #fff;
  // margin-left: 2%;
  width: 97%;
  .menu-icon {
    color: #000;
    width: 35px;
    font-size: 35px;
    // padding: 5px 0;
    padding: 3% 2% 4% 5%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    cursor: pointer;
    width: auto;
    margin-left: 15%;
  }
}

#media-menu-wrapper {
  display: none;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .1607843137254902);
  z-index: 100;
  position: absolute;
  width: 60vw;
  height: 200vh;
  top: -10px;

  li {
    // cursor: pointer;
    text-align: left;

    .pointer {
      cursor: pointer;
    }

    .main-line {
      border: 1px dashed #ccc;
      width: 94.8%;
      margin-left: 2%;
    }

    a {
      padding-left: 15%;
      display: block;
      height: 40px;
      padding-top: 15px;
      font: 18px/24px NotoSansKR_Medium;
      // float: left;
      .marker {
        margin-left: 5%;
        font: 18px/24px NotoSansKR_Bold;
        color: #005AAB;
      }
    }

    ul {
      li {
        display: flex;
        height: 30px;
        align-items: center;
        // padding-top: 5px;
      }
    }
  }


  .media-menu-header-container {
    background-color: #005AAB;
    font: 16px/24px NotoSansKR_Medium;
    color: #fff;
    .media-menu-header {
      display: flex;
      align-items: center;

      .media-logo {
        width: 70%;
        padding: 3% 4%;
      }

      .close {
        cursor: pointer;
        margin-left: 8%;
      }
    }

    .member-menu {
      padding-bottom: 2%;
      text-align: left;
      margin-left: 4%;

      .bar {
        margin: 0 5px;
      }
    }
  }

  .gnb {
    .subm {
      display: none;
      // background-color: #ccc;
      padding-left: 23%;

      .sub-dropdown {
        margin-left: 10%;
        font-size: 14px;
      }

      .dropdown-box-line {
        border: 1px dashed #ccc;
        width: 95%;
      }
    }

    .box-marker {
      font-size: 8px;
      text-align: center;
      width: 25px;
      // display: block;
      color: #005AAB;
    }

    .dropdown-line {
      border: 1px dashed #ccc;
      width: 95%;
    }
  }

}

@media (max-width: 550px) {

  .menu {
    width: 100vw !important;
  }
  .menu-icon {
    margin-left: 20px;
  }
  .menu-box {
    width: 94vw !important;
  }

  .logo {
    width: 80% !important;
    object-fit: contain;
    margin: 0 !important;
  }

  .menu-icon {
    font-size: 35px !important;
  }
  .member-menu {
    font-size: 15px;
  }
  .media-menu-header {
    padding: 10px 5px;
  }


  .media-logo {
    margin-right: 10px;
  }
  // .media-logo {
  //   width: 80%;
  // }
  
  .close {
    margin-left: 0 !important;
    font-size: 15px !important;
  }
}
</style>