<template>
    <div class="menu">
        <div class="menu-box">
            <img src="../../assets/icons/icon_title_logo.png" alt="logo" @click="MainRouter" class="logo">
            <div class="menu-wrapper">
                <ul class="gnb">
                    <li class="m1 men" :class="{ lineactive :  this.$store.state.lineActive1 , active : this.$store.state.overActive1}"  >
                        <a @click="ParkIntro">사업소개</a>
                        <ul class="subm1 subm">
                            <li class="m1-1" @click="ParkIntro">주차장 소개</li>
                        </ul>
                    </li>
                    <li class="m2 men" :class="{ lineactive :  this.$store.state.lineActive2 , active : this.$store.state.overActive2}" >
                        <a  @click="PriceRouter">이용안내</a>
                        <ul id="loginCheck" class="subm2 subm">
                            <li class="m1-1" @click="PriceRouter">주차요금 안내</li>
                            <li class="m1-1" @click="ParkingRouter">시설 현황</li>
                            <li class="m1-1" @click="IntroRouter">주차장 안내</li>
                            <li class="m1-1" @click="PassRouter">정기권 신청 및 미납조회</li>
                            <!-- <li class="m1-1 notice" v-if="this.$store.state.isLogin" @click="unpaidRouter">미납 조회</li> -->
                        </ul>
                    </li>
                    <li class="m3 men" :class="{ lineactive :  this.$store.state.lineActive3 }" >
                        <a  @click="NoticeRouter">고객지원</a>
                        <ul class="subm3 subm">
                            <li class="m1-1" @click="NoticeRouter">공지 사항</li>
                            <li class="m1-1" @click="faqRouter">자주하는 질문</li>
                            <li class="m1-1" @click="ServiceRouter">문의 사항</li>
                        </ul>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.mE();
        // this.loginCheckMenu()
    },
    updated() {
        // this.loginCheckMenu()
        
    },
    data() {
        return {
            msov: false,
        }
    },
    methods: {
        // 로그인 시 메뉴 높이 조절
        loginCheckMenu() {
            const secondMenu = document.getElementById('loginCheck')

            if (this.$store.state.isLogin) {
                secondMenu.style.bottom = '-207px'
            } else if (!this.$store.state.isLogin) {
                secondMenu.style.bottom = '-167px'
            }
        },
        mE() {
            const m1 = document.querySelector('.m1')
            const m2 = document.querySelector('.m2')
            const m3 = document.querySelector('.m3')


            m1.addEventListener('mouseover', () => {
                m1.classList.add('active')
            })
            m2.addEventListener('mouseover', () => {
                m2.classList.add('active')
            })
            m3.addEventListener('mouseover', () => {
                m3.classList.add('active')
            })
            m1.addEventListener('mouseout', (e) => {
                e.preventDefault();
                m1.classList.remove('active')
            })
            m2.addEventListener('mouseout', (e) => {
                e.preventDefault();
                m2.classList.remove('active')
            })
            m3.addEventListener('mouseout', (e) => {
                e.preventDefault();
                m3.classList.remove('active')
            })
        },
        MainRouter() {
            this.$router.push('/MainPage').catch(()=>{});
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        ParkIntro() {
            const m1 = document.querySelector('.m1')
            m1.classList.remove('active')
            this.$router.push('/Parking').catch(()=>{});
            this.$store.state.menuActive1 = true;
            this.$store.state.lineActive1 = true;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        NoticeRouter() {
            const m3 = document.querySelector('.m3')
            m3.classList.remove('active')
            this.$router.push('/Notice').catch(()=>{});
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
        },
        faqRouter() {
            const m3 = document.querySelector('.m3')
            m3.classList.remove('active')
            this.$router.push('/FAQ').catch(()=>{});
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
        },
        ServiceRouter() {
            const m3 = document.querySelector('.m3')
            m3.classList.remove('active')
            this.$router.push('/Service').catch(()=>{});
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
        },
        PriceRouter() {
            const m2 = document.querySelector('.m2')
            m2.classList.remove('active')
            this.$router.push('/Price').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;

        },
        SaleRouter() {
            this.$router.push('/Sale').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
            this.$store.state.overActive2 = false;
        },
        ParkingRouter() {
            const m2 = document.querySelector('.m2')
            
            this.$router.push('/InParking').catch(()=>{});
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = true;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;

            m2.classList.remove('active')
        },
        ParkingRouter2() {
            this.$router.push('/OutParking').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = true;
            this.$store.state.submenuActive3 = true;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        IntroRouter() {
            const m2 = document.querySelector('.m2')
            m2.classList.remove('active')
            this.$router.push('/IntroParking').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = true;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;

        },
        PassRouter() {
            const m2 = document.querySelector('.m2')
            m2.classList.remove('active')
            this.$router.push('/Randompass').catch(() => { });
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = true;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = true;
            this.$store.state.unpaidActive = false
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        unpaidRouter() {
            const m2 = document.querySelector('.m2')
            m2.classList.remove('active')
            this.$router.push('/unpaidPass').catch(() => { });
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.unpaidActive = true
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        }
        
    }
}
</script>
<style lang="scss" scoped>
.menu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 1300px;
    margin: auto;

    // justify-content: space-between;
    .menu-box {
        width: 100%;
        height: 100%;
        max-width: 1200px;
        display: flex;
        align-items: center;
        margin: auto;
        position: relative;
        z-index: 9;
    }

    .logo {
        cursor: pointer;
    }

    .menu-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        .e1 {
            position: absolute;
            bottom: 0;
        }
    }
    .gnb {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        font-family: 'NotoSansKR_Bold';
        color: #5A5B5E;
        position: absolute;
        align-items: center;
        top: 50%;
        right: -55px;
        transform: translateY(-50%);

        .men {
            z-index: 999;
            width: 180px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
                cursor: pointer;
                margin-right: 10px;
                position: relative;           
                border-bottom: 3px solid transparent;

            .subm {
                position: absolute;
                width: 100%;
                bottom: -43px;
                left: 50%;
                transform: translateX(-50%);
                box-shadow: 0.2px 2px 6px rgb(0, 0, 0, 0.3);
                li {
                    display: none;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    background-color: white;
                    border-bottom: 1px solid rgb(90, 91, 94,0.2);
                }

                .m1-1:hover {
                    background-color: #E0EBF5;
                    color: #005AAB;
                }
            }
            .subm2 {
                bottom: -167px;
            }
            .subm3 {
                bottom: -127px;
            }
        }
        li:last-child {
            margin-right: 0;
        }


        .men:hover {
            background-color: #005AAB;
            color: white;

        }
        .m1-1 {
            // padding: 0 2.89vw;
            position: relative;
            z-index: 99;
            color: #5A5B5E;
            font-size: 16px;
            font-family: 'NotoSansKR_Regular';
            cursor: pointer;
            opacity: 0;
            display: none;
            background-color: #fff;
        }
        .m1.active {
            border-bottom: 3px solid #8CB4D9;
            .m1-1 {
                display: flex;
                opacity: 1;
            }
        }
        .m1.lineactive {
            border-bottom: 3px solid #8CB4D9;
        }
        .m2.lineactive {
            border-bottom: 3px solid #8CB4D9;
        }
        .m3.lineactive {
            border-bottom: 3px solid #8CB4D9;
        }
        .m2.active {
            border-bottom: 3px solid #8CB4D9;
            .m1-1 {
                display: flex;
                opacity: 1;
            }
        }
        .m3.active {
            border-bottom: 3px solid #8CB4D9;
            .m1-1 {
                display: flex;
                opacity: 1;
            }
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 1300px) {

    .menu {

        .menu-wrapper {
            width: 45vw !important;
        }
        .menu-box {
            width: 100vw !important;
            max-width: 100vw !important;
            .logo {
                max-width: 43vw !important;
            }
        }
    }
}

</style>