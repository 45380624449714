<template>

    <div class="inner">
        <div class="SubMenuWrapper">
            <SubMenuBarM3 />
        </div>
        <div class="PageContentsWrapper">
            <router-view />
        </div>

    </div>
</template>
<script>
import SubMenuBarM3 from './SubMenuBar.vue';

export default {
    components: { SubMenuBarM3 }
}
</script>
<style lang="scss" scoped>
    .inner {
        display: flex;
        position: relative;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        justify-content: flex-end;
        .SubMenuWrapper {
            width: 288px;
            position: absolute;
            left: 0;
            top: -110px;
            height: 100%;
        }
        .PageContentsWrapper {
            box-sizing: border-box;
            padding-top: 74px;
            width: calc(100% - 318px);
            height: 100%;
            padding-bottom: 20px;
            min-height: calc(100vh - 100px - 60px);
        }
    }

    @media (max-width: 900px) {

        .PageContentsWrapper {
            width: 94% !important;
            margin-right: 3%;
        }
        .SubMenuWrapper {
            display: none !important;
        }
    }
</style>