<template>
    <div class="nav">
        <ul>
            <!-- <li v-if="!this.$store.state.isLogin" @click="checkLogin">확인</li>  -->
            <!-- <li @click="loginCheckRouter">이용약관 확인</li> -->
            <!-- <li @click="openAgreeApertInfo">동의서</li> -->
            <li v-if="!this.$store.state.isLogin" @click="LoginRouter">로그인</li>
            <li v-if="this.$store.state.isLogin" @click="MainRouter">로그아웃</li>
            <li v-if="!this.$store.state.isLogin" @click="RegisterRouter">회원가입</li>
            <li v-if="this.$store.state.isLogin" @click="AddcarRouter">마이페이지</li>
            <!-- v-if="this.$store.state.isLogin" -->
            <li @click="SitemapRouter">사이트맵</li>
        </ul>

        <SeasonInfoCheckModal
        :dialogVisibleSeasonTicketAgree.sync="openAgree" />

        <SeasonApertInfoCheckModal
        :dialogVisibleSeasonTicketAgreeApert.sync="openAgreeApert" />
    </div>
</template>

<script>
import build from '@/assets/api/axiosSetting'
import SeasonInfoCheckModal from '../M3_use/Pass/seasonTicket/modal/SeasonInfoCheckModal.vue'
import SeasonApertInfoCheckModal from '../M3_use/Pass/seasonTicket/modal/SeasonApertInfoCheckModal.vue'

export default {
    components: {
        SeasonInfoCheckModal,
        SeasonApertInfoCheckModal
    },
    data() {
        return {
            isLogin: this.$store.state.isLogin,
            openAgree: false,
            openAgreeApert: false,
        }
    },
    mounted() {
    },
    methods: {
        loginCheckRouter() {
            this.$router.push({name: '로그인확인'})
        },
        openAgreeInfo() {
            this.openAgree = !this.openAgree
        },
        openAgreeApertInfo() {
            this.openAgreeApert = !this.openAgreeApert
        },
        checkLogin() {
            build.post('/login',
                {
                    username: "user1",
                    password: "user1", 
                }
            ).then(res => {
                this.$store.state.isLogin = true;
                
                localStorage.setItem('accessTokenUser', res.data.body.accessToken)
                localStorage.setItem('accessTokenUserIndex', res.data.body.userId)

                this.$store.state.userName = res.data.body.name
            }).catch(err => {
                console.log('checkLogin error',err);
            })
        },
        deleteCookie(name) {
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
        MainRouter() {
            if (this.$store.state.isLogin === true) {
                this.$store.state.isLogin = false
                localStorage.removeItem('accessTokenUser')
                localStorage.removeItem('accessTokenUserIndex')
                // this.deleteCookie('popupCheck')

                this.$store.state.isLogin = false

                this.$store.state.userName = null

                alert('로그아웃되었습니다.')

                this.$store.state.isAdmin = false
            }

            // this.$router.push('/MainPage').catch(() => { });
            window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/126'
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;

        },
        LoginRouter() {
            window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/126'
            // this.$router.push('/Login').catch(() => { });
            // alert('서비스 준비중입니다.')
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        RegisterRouter() {
            // this.$router.push('/Register').catch(() => { });
            // alert('서비스 준비중입니다.')
            window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/127'
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        SitemapRouter() {
            this.$router.push('/Sitemap').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        AddcarRouter() {
            this.$router.push('/Addcar').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = true;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    ul {
        height: 100%;
        display: flex;
        color: white;
        font-family: 'NotoSansKR_Bold';
        font-size: 18px;
        align-items: center;
        box-sizing: border-box;

        li {
            box-sizing: border-box;
            cursor: pointer;
        }

        a {
            box-sizing: border-box;
            cursor: pointer;
            color: white;
            font-family: 'NotoSansKR_Bold';
            font-size: 18px;
            align-items: center;
            box-sizing: border-box;
            text-decoration: none;
        }

        li::after {
            margin: 0 0.5vw;
            content: "|";
        }

        a::after {
            margin: 0 0.5vw;
            content: "|";
        }

        li:last-child::after {
            margin: 0 0.1vw;
            content: "";
        }

        a:last-child::after {
            margin: 0 0.1vw;
            content: "";
        }

        li:hover {
            color: skyblue;
        }

        a:hover {
            color: skyblue;
        }

        li:hover::after {
            color: white;
        }

        a:hover::after {
            color: white;
        }
    }
}

@media (max-width: 1300px) {
    .nav {
        width: 98vw !important;
        padding-right: 20px !important;
    }
}

</style>
