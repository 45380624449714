import axios from "axios";

const request = axios.create({
  // baseURL: process.env.VUE_APP_BUILD_URL,
  // baseURL: "http://localhost:8080/api/user-or-admin",
  // baseURL: "http://219.248.246.69:9000/api/user-or-admin",
  baseURL: "https://smartparking.gwanakgongdan.or.kr:9000/api/user-or-admin",
  // withCredentials:true
});

request.interceptors.request.use(async config => {
  if (!config.headers["Authorization"]) {
    if (localStorage.getItem("accessTokenUser") && localStorage.getItem("accessTokenUser").length > 0) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem("accessTokenUser")}`;
    }
  }
  config.headers["Access-Control-Allow-Origin"] = "*"; // CORS 설정(모든 리소스 허용)
  return config;
});

export default request;
