<template>
    <div class="main"
    @mousedown="down" @touchstart.stop="down"
    @mousemove="move" @touchmove.stop="move"
    @mouseup="end" @touchend.stop="end">
        <div class="first-main">
            <div class="imgcover">
                <img src="../assets/main.jpg" alt="main" class="mainimg">
            </div>
            <div class="inner-box">
                <div class="text-box">
                    <h1>관악구시설관리공단</h1>
                    <h2>주차시설</h2>
                </div>
                <div class="mainmenu-box">
                    <div class="box1 box">
                        <div class="box-media-container">
                            <div class="box-text">주차장 소개</div>
                            <div class="box-line" />
                            <button id="parkingInfo" class="router-button" @click="ParkIntro">바로가기</button>
                        </div>
                        <img src="../assets/icons/icon_parking_intro.png" @click="ParkIntro" alt="box1">
                    </div>
                    <div class="box2 box">
                        <div class="box-media-container">
                            <div class="box-text">주차요금 안내</div>
                            <div class="box-line" />
                            <button id="parkingPrice" class="router-button" @click="PriceRouter">바로가기</button>
                        </div>
                        <img src="../assets/icons/icon_payment_info.png" @click="PriceRouter" alt="box2">
                    </div>
                    <div class="box3 box">
                        <div class="box-media-container">
                            <div class="box-text">시설 현황</div>
                            <div class="box-line" />
                            <button id="inParking" class="router-button" @click="ParkingRouter">바로가기</button>
                        </div>
                        <img src="../assets/icons/icon_facilities.png" @click="ParkingRouter" alt="box3">
                    </div>
                    <div class="box4 box">
                        <div class="box-media-container">
                            <div class="box-text">주차장 위치 안내</div>
                            <div class="box-line" />
                            <button id="introParking" class="router-button" @click="IntroRouter">바로가기</button>
                        </div>
                        <img src="../assets/icons/icon_location.png" @click="IntroRouter" alt="box4">
                    </div>
                    <div class="box5 box">
                        <div class="box-media-container">
                            <div class="box-text">정기권 신청 및 미납요금 조회</div>
                            <div class="box-line" />
                            <button id="seasonTicket" class="router-button" @click="PassRouter">바로가기</button>
                        </div>
                        <img src="../assets/icons/icon_season_unpaid.png" @click="PassRouter" alt="box5">
                    </div>
                    <div class="box6 box">
                        <img id="notice" src="../assets/icons/icon_notics.png" @click="NoticeRouter" alt="box6">
                    </div>
                    <div class="box7 box">
                        <img id="faq" src="../assets/icons/icon_qna.png" @click="faqRouter" alt="box7">
                    </div>
                    <div class="box8 box">
                        <img id="qna" src="../assets/icons/icon_FnA.png" @click="ServiceRouter" alt="box8">
                    </div>
                </div>
            </div>
        </div>
        <div class="second-main">
            <h1>관악구시설관리공단은 언제나<br>시민여러분의 의견을 기다립니다.</h1>
            <div class="notice-box">
                <div class="img-menu">
                    <div class="box1 box">
                        <img src="../assets/icons/icon_tile_payment.png"  @click="PriceRouter" alt="box2-1">
                    </div>
                    <div class="box2 box">
                        <img src="../assets/icons/icon_tile_facilities.png" @click="ParkingRouter" alt="box2-2">
                    </div>
                    <div class="box3 box">
                        <img src="../assets/icons/icon_tile_location.png" @click="IntroRouter" alt="box2-3">
                    </div>
                    <div class="box4 box">
                        <img src="../assets/icons/icon_tile_qna.png"  @click="ServiceRouter" alt="box2-4">
                    </div>
                </div>
                <div class="notice-menu">
                    <el-tabs id="tabs" ref="tabs" type="card" @tab-click="handleClick"> 

                        <el-button ref="btn" class="button" type="text" @click="goPath">more</el-button>
                        
                        <!-- &#43; -->

                        <el-tab-pane v-for="(item, index) in title" :key="index" :label="item.path">
                            <el-table :data="tableArray" :total="5" :show-header="false" 
                            empty-text="등록된 정보가 없습니다."  @cell-click="goBoardInfo" >
                                <el-table-column id="title" prop="title" align="center">
                                </el-table-column>
                                <el-table-column id="insertDate" prop="insertDate" align="center">
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                    </el-tabs>
                </div>
            </div>

            <div class="mobile-notice-menu">
                <div class="mobileTabs">
                    <div class="mobileTabsBtn">
                        <el-button class="noticeBtn" type="text">공지사항</el-button>
                    </div>

                    <hr class="tabLine1" />
                    <el-table class="tabTable" :data="tableArray"
                    empty-text="등록된 것이 없습니다."  style="width:340px; height: 240px">
                        <el-table-column prop="title" width="auto"/>
                        <el-table-column prop="insertDate" width="100px"/>
                    </el-table>

                    <el-button type="text" id="more" class="more" @click="NoticeRouter">더보기 + </el-button>

                </div>

                <div class="mobileTabs">
                    <div class="mobileTabsBtn">
                        <el-button id="faqButton" class="eachBtn" type="text"
                        :class="{ active: this.isFaq }">
                            자주하는질문
                        </el-button>
                        <el-button id="qnaButton" class="eachBtn" type="text"
                        :class="{ active: this.isQna }">
                            문의사항
                        </el-button>
                    </div>

                    <hr class="tabLine1" />
                    <el-table class="tabTable" :data="tableSecondArray"
                    empty-text="등록된 것이 없습니다." style="width:340px; height: 240px">
                        <el-table-column prop="title" width="auto"/>
                        <el-table-column prop="insertDate" width="100px"/>
                    </el-table>

                    <el-button type="text" id="more" class="more" @click="passTabsRouter">더보기 + </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import documentBuild from '@/assets/api/documentSetting'

export default {
    mounted() {

        this.openPopup()

        var value = document.cookie.match('(^|;) ?' + 'accessToken' + '=([^;]*)(;|$)');
        var valueName = document.cookie.match('(^|;) ?' + 'name' + '=([^;]*)(;|$)');
        var valueId = document.cookie.match('(^|;) ?' + 'id' + '=([^;]*)(;|$)');

        if (value !== null && valueName !== null) {

            var accessToken = document.cookie.match('(^|;) ?' + 'accessToken' + '=([^;]*)(;|$)');
            localStorage.setItem('accessTokenUser', accessToken[2])
            
            if (valueId !== null) {
                var accessTokenId = document.cookie.match('(^|;) ?' + 'id' + '=([^;]*)(;|$)');
                localStorage.setItem('accessTokenUserIndex', accessTokenId[2])
            }

            this.$store.state.isLogin = true

            this.deleteCookie('accessToken')
            this.deleteCookie('id')
            this.deleteCookie('name')
            
        }
        
        window.scrollTo (0, 0)

        const scrollBar = this.$refs.tabs.$el.querySelector(".el-tabs__nav-scroll");
        scrollBar.appendChild(this.$refs.btn.$el);

        this.checkQna()

        this.setTableInfo()

        this.tabButtonTouch()

        this.isMobile();

        window.addEventListener('touchmove', () => {
            this.mobileTouch = false;
        });

        window.addEventListener('touchstart', () => {
            this.mobileTouch = true;
        });

        window.addEventListener('touchcancel', () => {
            this.mobileTouch = true;
        });

        window.addEventListener('touchend', () => {
            this.mobileTouch = true;
        });

    },
    unmounted() {

        window.removeEventListener('touchmove', () => {});
        window.removeEventListener('touchstart', () => {});
        window.removeEventListener('touchcancel', () => {});
        window.removeEventListener('touchend', () => {});

    },
    data() {
        return {
            idx: 0,
            name: "공지사항",
            tableArray: [],
            tableSecondArray: [],
            title :[
                {id: 0, path: '공지사항'},
                {id: 1, path: '자주하는질문'},
                {id: 2, path: '문의사항'}
            ],
            isFaq: true,
            isQna: false,
            titleMenu: "자주하는질문",
            titleNotice: "Notice",
            startEvent: false,
            moveEvent: false,
            flags: false,
            dx: 0,
            dy: 0,
            position: {
                x: 320,
                y: 60
            },
            screenWidth: window.screen.width,
            screenHeight: window.screen.height,
            mobileTouch: true,
            checkMobile: false
        }
    },
    methods: {
        openPopup() {
            if (this.checkMobile === false) {
                window.open('/endPopup', '_blank', 'width=850px, height=440px, top=50, left=50, scrollbars= 0, toolbar=0, menubar=no');
            } else if (this.checkMobile === true) {
                window.open('/endPopup', '_blank', 'width=100%, height=100%, top=0, left=0, scrollbars= 0, toolbar=0, menubar=no');
            }
        },
        // 쿠키셋팅
        setCookies(name, value, days) {
            var expires = ''
            if (days) {
                var date = new Date()
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
                expires = '; expires=' + date.toUTCString()
            } else {
                expires = ''
            }

            document.cookie = name + '=' + value + expires + '; path=/'
        },
        // 쿠키 가져오기
        getCookies(name) {
            var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
            return value? value[2] : null;
        },
        deleteCookie(name) {
            document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
        setTableInfo() {
            documentBuild.get('/posts/page-info',
                {
                    params: {
                        page: 0,
                        size: 5,
                        forum: 'NOTICE_FORUM'
                    }
                }
            ).then(res => {
                this.tableArray = []
                const listInfo = res.data.body.content

                for (const listIndex in listInfo) {
                    this.tableArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                }

                this.setSecondTableInfo()
            }).catch(err => {
                console.log(err);
                // if (err.response) {
                //     alert(err.response.data.message)
                // }
            })
        },
        setSecondTableInfo() {
            documentBuild.get('/posts/page-info',
                {
                    params: {
                        page: 0,
                        size: 5,
                        forum: 'FAQ_FORUM'
                    }
                }
            ).then(res => {
                this.tableSecondArray = []
                const listInfo = res.data.body.content

                for (const listIndex in listInfo) {
                    this.tableSecondArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                }
            }).catch(err => {
                console.log(err);
            })
        },
        setQASecondTableInfo() { 
            documentBuild.get('/posts/page-info',
                {
                    params: {
                        page: 0,
                        size: 5,
                        forum: 'QNA_FORUM'
                    }
                }
            ).then(res => {
                this.tableSecondArray = []
                const listInfo = res.data.body.content

                for (const listIndex in listInfo) {
                    this.tableSecondArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                }
            }).catch(err => {
                console.log(err);
            })
        },
        handleClick(tab) {
            this.idx = tab.index;
            this.name = tab.label;

            if (tab.label === '공지사항') {
                documentBuild.get('/posts/page-info',
                    {
                        params: {
                            page: 0,
                            size: 5,
                            forum: 'NOTICE_FORUM'
                        }
                    }
                ).then(res => {
                    this.tableArray = []
                    const listInfo = res.data.body.content

                    for (const listIndex in listInfo) {
                        this.tableArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                    }
                }).catch(err => {
                    console.log(err);
                })
            } else if (tab.label === '자주하는질문') {
                documentBuild.get('/posts/page-info',
                    {
                        params: {
                            page: 0,
                            size: 5,
                            forum: 'FAQ_FORUM'
                        }
                    }
                ).then(res => {
                    this.tableArray = []
                    const listInfo = res.data.body.content

                    for (const listIndex in listInfo) {
                        this.tableArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                    }
                }).catch(err => {
                    console.log(err);
                })
            } else if (tab.label === '문의사항') {
                documentBuild.get('/posts/page-info',
                    {
                        params: {
                            page: 0,
                            size: 5,
                            forum: 'QNA_FORUM'
                        }
                    }
                ).then(res => {
                    this.tableArray = []
                    const listInfo = res.data.body.content

                    for (const listIndex in listInfo) {
                        this.tableArray.push({ title: listInfo[listIndex].title, insertDate: this.getFormatDate(listInfo[listIndex].lastModifiedDate)})
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
        },
        // 일시 포멧
        getFormatDate(date) {
            var yyyy = new Date(date).getFullYear()

            var MM = new Date(date).getMonth() + 1
            MM = MM >= 10 ? MM : '0' + MM

            var dd = new Date(date).getDate()
            dd = dd >= 10 ? dd : '0' + dd

            return yyyy + '-' + MM + '-' + dd
        },
        checkQna() {

            const faqButton = document.getElementById("faqButton");

            document.addEventListener('touchstart', (e) => {
                e.preventDefault();

            }, { passive: false });

            document.addEventListener('touchmove', (e) => {
                e.preventDefault();

            }, { passive: false });

            /* click */
            faqButton.addEventListener('click', () => {

                this.isFaq = true;
                this.isQna = false;

                this.setSecondTableInfo()

                this.tableSecondArray = []

            })

            faqButton.addEventListener('touchend', (ev) => {
                ev.preventDefault();

                this.isFaq = true;
                this.isQna = false;

                this.setSecondTableInfo()

                this.tableSecondArray = []

            }, false)

            const qnaButton = document.getElementById("qnaButton");

            document.addEventListener('touchstart', (e) => {
                e.preventDefault();

            }, { passive: false });

            document.addEventListener('touchmove', (e) => {
                e.preventDefault();

            }, { passive: false });

            /* click */
            qnaButton.addEventListener('click', () => {

                this.isFaq = false;
                this.isQna = true;

                this.setQASecondTableInfo()

            })

            qnaButton.addEventListener('touchend', (ev) => {
                ev.preventDefault();

                this.isFaq = false;
                this.isQna = true;

                this.setQASecondTableInfo()

            }, false)
        },
        goPath() {
            this.$router.push({ name: this.name })
        },
        goBoardInfo() {

            if (this.idx == 0) {

                this.$router.push({ name: '공지사항' });

            } else if (this.idx == 1) {

                this.$router.push({ name: '자주하는질문' });

            } else if (this.idx == 2) {

                this.$router.push({ name: '문의사항', });
            }
        },
        MenuReset() {
            this.$store.state.lineActive1 = true;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        ParkIntro() {
            this.$router.push({ name: '주차장소개' }).catch(() => { });
            this.$store.state.menuActive1 = true;
            this.$store.state.lineActive1 = true;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        NoticeRouter() {
            this.$router.push('/Notice').catch(() => { });
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
        },
        faqRouter() {
            this.$router.push('/FAQ').catch(() => { });
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
            window.scrollBy(top);
        },
        ServiceRouter() {
            this.$router.push('/Service').catch(() => { });
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = true;
        },
        PriceRouter() {
            this.$router.push('/Price').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        SaleRouter() {
            this.$router.push('/Sale').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.menuActive3 = true;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        ParkingRouter() {
            this.$router.push('/InParking').catch(() => { });
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = true;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        ParkingRouter2() {
            this.$router.push('/OutParking').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = true;
            this.$store.state.submenuActive3 = true;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        IntroRouter() {
            this.$router.push('/IntroParking').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = true;
            this.$store.state.BmenuActive = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;

        },
        PassRouter() {
            this.$router.push('/Randompass').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.AmenuActive = false;
            this.$store.state.BmenuActive = true;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = true;
            this.$store.state.lineActive3 = false;
        },
        down(event) {

            this.flags = true;

            var touch;

            if (event.touches) {
                touch = event.touches[0];
            } else {
                touch = event;
            }

            this.dx = touch.clientX - event.target.offsetLeft
            this.dy = touch.clientY - event.target.offsetTop

        },
        move(event) {

            if (this.flags) {
                var touch;

                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }

                this.position.x = touch.clientX - this.dx;
                this.position.y = touch.clientY - this.dy;

                if (this.position.x < 0) {
                    this.position.x = 0
                }
                else if (this.position.x > this.screenWidth - touch.target.clientWidth) {
                    this.position.x = this.screenWidth - touch.target.clientWidth
                }

                if (this.position.y < 0) {
                    this.position.y = 0
                } else if (this.position.y > this.screenHeight - touch.target.clientHeight) {
                    this.position.y = this.screenHeight - touch.target.clientHeight
                }

                document.addEventListener("touchmove",(ev) => {
                    ev.preventDefault();
                },false);
            }
        },
        end() {
            this.flags = false;
        },
        isMobile() {
            var user = navigator.userAgent;

            if (user.indexOf("iPhone") > -1
                || user.indexOf("Android") > -1
                || user.indexOf("iPad") > -1
                || user.indexOf("iPod") > -1
            ) {
                this.checkMobile = true;
            }
        },
        mobileRouter() {
            // 주차장 소개
            const parkingInfo = document.getElementById('parkingInfo')
            // 주차요금 안내
            const parkingPrice = document.getElementById('parkingPrice')
            // 시설현황
            const inParking = document.getElementById('inParking')
            // 주차장 위치 안내
            const introParking = document.getElementById('introParking')
            // 정기권 신청
            const seasonTicket = document.getElementById('seasonTicket')
            // 공지사항
            const notice = document.getElementById('notice')
            // 자주묻는 질문
            const faq = document.getElementById('faq')
            // 문의사항
            const qna = document.getElementById('qna')


            /* 초기화 */
            document.addEventListener('touchstart', (e) => {
                e.preventDefault();

                this.startEvent = true;
            }, { passive: false });

            document.addEventListener('touchmove', (e) => {
                e.preventDefault();

                console.log('startEvent', this.startEvent);

                if (!this.startEvent) {
                    return;
                }
                this.moveEvent = true

            }, { passive: false });


            // 주차장소개
            parkingInfo.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) {
                    this.$router.push({ name: '주차장소개' }).catch(() => { });
                    
                }

            }, false)

            parkingInfo.addEventListener('click', () => {

                this.$router.push({ name: '주차장소개' }).catch(() => { });

            }, false)


            // 요금안내
            parkingPrice.addEventListener('touchend', (ev) => {
                ev.preventDefault()
                if (this.mobileTouch) {
                    this.$router.push({ name: '요금안내' }).catch(() => { });
                }
            }, false)


            parkingPrice.addEventListener('click', () => {

                this.$router.push({ name: '요금안내' }).catch(() => { });
            }, false)


            // 시설현황
            inParking.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) {
                    this.$router.push({ name: '노상주차장현황' }).catch(() => { });
                }

            }, false)

            inParking.addEventListener('click', () => {

                this.$router.push({ name: '노상주차장현황' }).catch(() => { });

            }, false)

            // 주차장 위치 안내
            introParking.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) { 
                    this.$router.push({ name: '주차장안내' }).catch(() => { });
                }

            }, false)

            introParking.addEventListener('click', () => {

                this.$router.push({ name: '주차장안내' }).catch(() => { });

            }, false)

            // 정기권 신청
            seasonTicket.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) { 
                    this.$router.push({ name: '추첨형정기권신청' }).catch(() => { });
                }

            }, false)


            seasonTicket.addEventListener('click', () => {

                this.$router.push({ name: '추첨형정기권신청' }).catch(() => { });

            }, false)

            // 공지사항
            notice.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) {
                    this.$router.push({ name: '공지사항' }).catch(() => { });
                }

            }, false)


            notice.addEventListener('click', () => {

                this.$router.push({ name: '공지사항' }).catch(() => { });

            }, false)

            // 자주하는 질문
            faq.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) { 
                    this.$router.push({ name: '자주하는질문' }).catch(() => { });
                }

            }, false)

            faq.addEventListener('click', () => {

                this.$router.push({ name: '자주하는질문' }).catch(() => { });

            }, false)

            // 문의사항
            qna.addEventListener('touchend', (ev) => {
                ev.preventDefault()

                if (this.mobileTouch) { 
                    this.$router.push({ name: '문의사항' }).catch(() => { });

                }

            }, false)

            qna.addEventListener('click', () => {

                this.$router.push({ name: '문의사항' }).catch(() => { });

            }, false)

        },
        tabButtonTouch() {
            // 자주하는 질문
            const faqButton = document.getElementById('faqButton')
            // 문의사항
            const qnaButton = document.getElementById('qnaButton')

            // 초기화
            document.addEventListener('touchstart', (e) => {
                e.preventDefault();

                this.startEvent = true;
            },{passive: false});

            document.addEventListener('touchmove', (e) => {
                e.preventDefault();

                if(!this.startEvent) {
                    return;
                }
                this.moveEvent = true
                
            },{passive: false});

            // 자주하는 질문
            faqButton.addEventListener('click', () => {

                this.titleMenu = "자주하는질문";

                this.isFaq = true;
                this.isQna = false;

            })

            faqButton.addEventListener('touchend', (ev) => {
                ev.preventDefault();

                this.titleMenu = "자주하는질문";

                this.isFaq = true;
                this.isQna = false;

            }, false)

            // 문의사항
            qnaButton.addEventListener('click', () => {

                this.titleMenu = "문의사항";

                this.isFaq = false;
                this.isQna = true;

            })

            qnaButton.addEventListener('touchend', (ev) => {
                ev.preventDefault();

                this.titleMenu = "문의사항";

                this.isFaq = false;
                this.isQna = true;

            }, false)
        },
        passTabsRouter() {
            this.$router.push({name: this.titleMenu})
        }
    }

}
</script>

<style lang="scss" scoped>

.box-text {
    display: none;
}
.box-line {
    display: none;
}
.router-button {
    display: none;
}
.mobile-notice-menu {
    display: none;
}
.main {
    width: 100%;
    height: calc(2002px - 168px - 100px - 60px);
    position: relative;
    box-sizing: border-box;

    h1 {
        margin: 0;
        font-weight: normal;
        text-align: center;
    }

    h2 {
        font-family: 'NotoSansKR_Bold';
    }

    .first-main {
        width: 98%;
        height: 891px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        margin: auto;
        .imgcover {
            top:0;
            left: 0;
            box-sizing: border-box;
            position: absolute;
            z-index: -9;
            width: 100%;
            height: 100%;
        }

        .mainimg {
            top:0;
            left: 0;
            box-sizing: border-box;
            width: 100%;
            object-fit: cover;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.7;

        }

        .inner-box {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            padding: 0;
            color: white;
            // display: flex;
            max-width: 1200px;
            margin: auto;
            overflow: hidden;

            .text-box {
                position: absolute;
                margin-top: 170px;

                h1,
                h2 {
                    margin: 0;
                    padding: 0;
                    font-size: 60px;
                    text-align: left;
                    text-shadow: 0px 3px 6px #00000059;
                }

            }
        }

        .mainmenu-box {
            box-sizing: border-box;
            // width: 100%;
            // height: 100%;
            position: relative;
            top: 170px;

            .box {
                width: 240px;
                height: 200px;
                border-radius: 20px;
                // opacity: 0.8;
                position: absolute;
                left: auto;
                cursor: pointer;
                padding: 10px;
                box-sizing: border-box;
                font-family: 'NotoSansKR_Bold';
                font-size: 20px;
                letter-spacing: -0.5px;
                color: #FFFFFF;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }

            .box1 {
                right: 260px;
                background-color: rgb(0, 155, 192,0.8);
            }

            .box2 {
                right: 0;
                background-color: rgb(31, 199, 210, 0.8);
            }

            .box3 {
                top: 220px;
                right: 520px;
                background-color: rgb(0, 121, 194, 0.8);
            }

            .box4 {
                top: 220px;
                right: 260px;
                background-color: rgb(0, 155, 192, 0.8);
            }

            .box5 {
                top: 220px;
                right: 0;
                background-color: rgb(31, 199, 210, 0.8);
            }

            .box6 {
                top: 440px;
                right: 520px;
                background-color: rgb(255, 255, 255, 0.8);
                color: #005AAB;
            }

            .box7 {
                top: 440px;
                right: 260px;
                background-color: rgb(255, 255, 255, 0.8);
                color: #0079C2;
            }

            .box8 {
                top: 440px;
                right: 0;
                background-color: rgb(255, 255, 255, 0.8);
                color: #009BC0;
            }
        }
    }

    .second-main {
        width: 100%;
        height: auto;
        margin-top: 129px;
        color: #000000;
        .notice-box {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            max-width: 1080px;
            margin: auto;
            margin-top: 68px;
            justify-content: space-between;
            .img-menu {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                width: 50%;
                .box {
                    width: 260px;
                    height: 155px;
                    border-radius: 20px;
                    font-size: 25px;
                    font-family: 'NotoSansKR_Medium';
                    letter-spacing: -0.63px;
                    color: #FFFFFF;
                    padding: 10px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    position: relative;
                    cursor: pointer;
                }
                // .box1 {
                //     background-color: #005AAB;
                // }
                // .box2 {
                //     background-color: #0079C2;
                // }
                // .box3 {
                //     background-color: #009BC0;
                // }
                // .box4 {
                //     background-color: #1FC7D2;
                // }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            .notice-menu {
                box-sizing: border-box;
                width: 48%;
                // border: 1px solid black;

                #tabs {
                    margin-left: 3%;
                    float: left;
                    width: 100%;
                    height: auto;
                    text-align: center;

                    .button {
                        float: right;
                        border: none;
                        width: 24px;
                        height: 24px;
                        text-align: center;
                        font: 15px/26px NotoSansKR_Medium;
                        margin-right: 4%;
                        color: #838383;
                        padding-top: 2% !important;
                    }

                    ::v-deep {

                        .el-table__empty-text {
                            font: 15px/29px NotoSansKR_Medium;
                        }
                        .el-tabs__nav-scroll {
                            display: flex !important;
                            justify-content: space-between !important;
                            margin-bottom: 0.4%;
                        }

                        .el-tabs__header {
                            border-bottom: 2px solid #3287C3;
                            margin-bottom: 0 !important;

                            .el-tabs__item {
                                padding-top: 5px;
                                text-align: center;
                                font: 20px/39px NotoSansKR_Medium;
                                letter-spacing: -0.5px;
                                color: #838383;
                            }
                            .el-tabs__item.is-active {
                                text-align: center;
                                font: 20px/39px NotoSansKR_Bold;
                                color: #FFFFFF;
                                background: #3287C3 0% 0% no-repeat padding-box;
                            }
                        }

                        .el-tabs__content {
                            background: rgb(255, 255, 255);
                            /* height: 330px; */
                        }

                        .el-tabs__item {
                            height: 50px;
                            background: #FFFFFF;
                        }
                        .el-table__fixed,.el-table__fixed-right {
                            box-shadow: none;
                        }

                        .cell {
                            text-align: left;
                            margin-left: 8%;
                            font: 15px/29px NotoSansKR_Medium;
                            letter-spacing: 0px;
                            color: #5A5B5E;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

}

@media (max-width: 1300px){
    .main {
        width: 100vw !important;
    }

    h1 {
        font-size: 40px !important;
    }

    h2 {
        font-size: 40px !important;
    }

    .text-box {
        margin-left: 2vw;
    }

    .mainmenu-box {
        width: 100vw !important;
        .box {
            margin-right: 2vw;
            width: 220px !important;
        }
        .box2 {
            right: 18px !important;
        }

        .box3 {
            right: 500px !important;
        }

        .box5 {
            right: 18px !important;
        }

        .box6 {
            right: 500px !important;
        }

        .box8 {
            right: 18px !important;
        }

    }
    
    .notice-box {
        width: 98vw !important;
        justify-content: normal !important;
    }

    .img-menu {
        margin-left: 2vw;
        width: 48vw !important;
        img {
            width: 100%;
        }

        .box {
            width: 47% !important;
            height: 155% !important;
        }
    }

    #tabs {
        width: 44vw !important;

        ::v-deep {

            .cell {
                font: 14px/24px NotoSansKR_Medium !important;
            }
            .el-tabs__item {
                height: 40px !important;
                padding: 7px 10px !important;
            }
            .el-tabs__header {
                .el-tabs__item {
                    width: 10vw;
                    font: 14px/24px NotoSansKR_Medium !important;
                    
                }
            }
        }
    }
}

@media (max-width: 900px) {

    .main {
        height: 100% !important;

        h1 {
            font-size: 30px !important;
        }
    }

    .first-main {
        height: 55% !important;

        .inner-box {
            .text-box {
                display: flex;
                justify-content: center;
                width: 100% !important;
                height: 150px !important;
                margin-top: 18% !important;
                margin-left: 0 !important;

                h1 {
                    margin-right: 3% !important;
                }
            }
        }

        .mainmenu-box {
            position: inherit !important;
            margin-top: 30%;
            display: flex !important;
            flex-direction: column;
            align-items: center;

            .box {
                width: 80% !important;
                position: inherit !important;
                height: 120px !important;
                flex-direction: column;
                align-items: normal !important;
                margin-bottom: 2%;
            }

            .box6 {
                display: none !important;
            }
            .box7 {
                display: none !important;
            }
            .box8 {
                display: none !important;
            }

            img {
                position: inherit !important;
                display: none !important;
            }

            .box-media-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                .box-text {
                    display: block !important;
                }

                .box-line {
                    display: block !important;
                    border-bottom: 2px solid #fff;
                    margin-top: 1px;
                    margin-bottom: 1px;
                    width: 100%;
                }
                .router-button {
                    display: block !important;
                    height: 40px;
                    width: 80%;
                    font: 16px/24px NotoSansKR_Medium !important;
                    color: #009bc0;
                    border: transparent;
                    background-color: #fff;
                    border-radius: 8px;
                    margin-top: 2.5%;
                    cursor: pointer;
                    // z-index: 100;
                }
            }


        }
    }

    .img-menu {
        display: none !important;
    }

    .second-main {
        .mobile-notice-menu {
            display: block !important;
            margin-top: 2%;

            .mobileTabs {
                width: 80vw;
                display: inline-block;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 10px;
                margin-bottom: 2vh;
                overflow: hidden;
                // z-index: 100;

                .mobileTabsBtn {
                    height: 50px !important;
                    text-align: left;
                }

                ::v-deep {
                    .el-table__body-wrapper {
                        font: 12px/15px NotoSansKR_Medium;
                    }
                }

                .noticeBtn {
                    padding: 0;
                    margin: 0;
                    padding-bottom: 2%;
                    border-bottom: 3px solid #008FD2 !important; 
                    border-radius: 0;
                    font: 12px/15px NotoSansKR_Medium;
                    letter-spacing: 0px;
                    color: #191C1D;
                    padding-top: 20px;
                    margin-left: 3%;
                }
                .eachBtn {
                    padding: 0;
                    margin: 0;
                    padding-bottom: 2%;
                    font: 12px/15px NotoSansKR_Medium;
                    letter-spacing: 0px;
                    color: #191C1D;
                    padding-top: 20px;
                    margin-left: 3%;
                }

                .eachBtn.active {
                    border-bottom: 3px solid #008FD2 !important; 
                    border-radius: 0;
                }

                .tabTable {
                    width: 79vw !important;
                    font: 12px/15px NotoSansKR_Medium;
                    letter-spacing: 0px;
                    color: #4C4C4C;
                    ::v-deep {
                        .el-table__header-wrapper {
                            display: none;
                        }
                    }
                }
                .more {
                    // margin-left: 34vw;
                    font: 12px/15px NotoSansKR_Medium;
                }
            }
        }

        .notice-box {
            display: none !important;
        }
    }



}

@media(max-width: 660px) {
    .first-main {
        .text-box {
            flex-direction: column;
            align-items: center;
        }

        .mainmenu-box {
            margin-top: 46% !important;
        }
    }
}

@media(max-width: 400px) {
    .main {
        h1 {
            font-size: 25px !important;
            margin-bottom: 20px;
        }
    }
    .first-main {

        .mainmenu-box {
            margin-top: 60% !important;
        }
    }
}

</style>