import Vue from 'vue'
import VueRouter from 'vue-router'

import MainLayout from '@/views/MainLayout.vue'
import PageLayout from '@/views/M2_business/1_Layout/PageLayout.vue'
import PageLayoutM2 from '@/views/M3_use/1_Layout/PageLayout.vue'
import PageLayoutM3 from '@/views/M4_help/1_Layout/PageLayout.vue'
import PageLayoutM4 from '@/views/M5_mypage/1_Layout/PageLayout.vue'
import MainPage from '@/views/MainPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: MainLayout,
    redirect: "/MainPage",
    // children: [

    // ],
  },
  {
    path: "/MainPage",
    // name: "메인페이지",
    component: MainLayout,
    children: [
      {
        path: "/",
        name: "메인페이지",
        component: MainPage,
      },
    ],
  },
  {
    path: "/navmenu",
    name: "네비게이션메뉴",
    component: MainLayout,
    children: [
      // {
      //   path: "/Register",
      //   name: "회원가입",
      //   component: () => import("@/views/M1_user/RegisterPage.vue"),
      // },
      // {
      //   path: "/Login",
      //   name: "로그인",
      //   component: () => import("@/views/M1_user/LoginPage.vue"),
      // },
      {
        path: "/SiteMap",
        name: "사이트맵",
        component: () => import("@/views/M1_user/SiteMapPage.vue"),
      },
    ],
  },
  {
    path: "/LoginCheck",
    name: "로그인연결",
    component: () => import("@/views/M1_user/LoginPassPage.vue"),
  },
  {
    path: "/intro",
    name: "사업소개",
    component: PageLayout,
    children: [
      {
        path: "/Parking",
        name: "주차장소개",
        component: () => import("@/views/M2_business/ParkingIntro/ParkingIntro.vue"),
      },
    ],
  },
  {
    path: "/use",
    name: "이용안내",
    component: PageLayoutM2,
    children: [
      {
        path: "/Price",
        name: "요금안내",
        component: () => import("@/views/M3_use/Price/PricePage.vue"),
      },
      {
        path: "/Sale",
        name: "할인안내",
        component: () => import("@/views/M3_use/Price/SalePage.vue"),
      },
      {
        path: "/Inparking",
        name: "노상주차장현황",
        component: () => import("@/views/M3_use/Parking/InParking.vue"),
      },
      {
        path: "/Outparking",
        name: "노외주차장현황",
        component: () => import("@/views/M3_use/Parking/OutParking.vue"),
      },
      {
        path: "/Introparking",
        name: "주차장안내",
        component: () => import("@/views/M3_use/Introduce/IntroParking.vue"),
      },
      {
        path: "/Pass",
        name: "선착순정기권",
        component: () => import("@/views/M3_use/Pass/PassPage.vue"),
      },
      {
        path: "/PassDetail",
        name: "선착순정기권신청",
        component: () => import("@/views/M3_use/Pass/seasonTicket/SeasonTicketApplication.vue"),
      },
      {
        path: "/Randompass",
        name: "추첨형정기권",
        component: () => import("@/views/M3_use/Pass/RandomPassPage.vue"),
      },
      {
        path: "/RandomPassDetail",
        name: "추첨형정기권신청",
        component: () => import("@/views/M3_use/Pass/randomSeasonTicket/RandomSeasonTicketApplication.vue"),
      },
      {
        path: "/unpaidPass",
        name: "미납조회",
        component: () => import("@/views/M3_use/unpaid/unpaidPage.vue"),
      },
      {
        path: "/unpaidPayment",
        name: "미납결제",
        component: () => import("@/views/M3_use/unpaid/UnpaidPaymentPage.vue"),
      },
    ],
  },

  // 로그인
  {
    path: "/auth",
    component: () => import("@/views/M1_user/1_Layout/LoginLayout.vue"),
    redirect: "/auth/Register",
    children: [
      {
        path: "/auth/Register",
        name: "로그인확인",
        component: () => import("@/views/M1_user/LoginRegister.vue"),
      },
    ],
  },

  {
    path: "/help",
    name: "고객지원",
    component: PageLayoutM3,
    children: [
      {
        path: "/Notice",
        name: "공지사항",
        component: () => import("@/views/M4_help/Notice/NoticePage.vue"),
      },
      {
        path: "/FAQ",
        name: "자주하는질문",
        component: () => import("@/views/M4_help/FAQ/FAQPage.vue"),
      },
      {
        path: "/Service",
        name: "문의사항",
        component: () => import("@/views/M4_help/Service/ServicePage.vue"),
      },
      {
        path: "/ServiceWrite",
        name: "문의사항작성",
        component: () => import("@/views/M4_help/Service/QAView/QAWrtie.vue"),
      },
      {
        path: "/viewNotice",
        name: "공지사항게시물",
        component: () => import("@/views/M4_help/Notice/NoticeViewPageMainManage.vue"),
      },
      {
        path: "/viewFAQ",
        name: "자주하는질문게시물",
        component: () => import("@/views/M4_help/FAQ/FAQViewPageMainManage.vue"),
      },
      {
        path: "/viewQA",
        name: "문의사항게시물",
        component: () => import("@/views/M4_help/Service/QAViewPageMainManage.vue"),
      },
    ],
  },

  {
    path: "/mypage",
    name: "마이페이지",
    component: PageLayoutM4,
    children: [
      {
        path: "/Addcar",
        name: "차량등록현황",
        component: () => import("@/views/M5_mypage/AddCar/AddCar.vue"),
      },
      {
        path: "/DiscountCar",
        name: "할인등록현황",
        component: () => import("@/views/M5_mypage/AddCar/AddDiscount.vue"),
      },
      {
        path: "/Usepass",
        name: "정기권사용내역",
        component: () => import("@/views/M5_mypage/UsePass/UsePass.vue"),
      },
      {
        path: "UsePassPayment",
        name: "정기권결제",
        component: () => import("@/views/M5_mypage/UsePass/payment/UsePassPayment.vue"),
      },
      {
        path: "/Signpass",
        name: "정기권신청내역",
        component: () => import("@/views/M5_mypage/SignPass/SignPass.vue"),
      },
      {
        path: "/Paymentpass",
        name: "정기권구매내역",
        component: () => import("@/views/M5_mypage/PaymentPass/PaymentPass.vue"),
      },
      // {
      //   path: "/Editmem",
      //   name: "회원정보 수정",
      //   component: () => import("@/views/M5_mypage/Edit/EditMember.vue"),
      // },
      {
        path: "/Leavemem",
        name: "회원 탈퇴",
        component: () => import("@/views/M5_mypage/Leave/LeaveMember.vue"),
      },
      {
        path: "/paymentCheck",
        name: "결제확인",
        component: () => import("@/views/M5_mypage/PaymentResult/PaymentCheck.vue"),
      },
    ],
  },
  {
    path: "/paymentSuccess",
    name: "결제성공",
    component: () => import("@/views/M5_mypage/PaymentResult/PaymentSuccess.vue"),
  },
  {
    path: "/paymentError",
    name: "결제실패",
    component: () => import("@/views/M5_mypage/PaymentResult/PaymentError.vue"),
  },
  {
    path: "/popup",
    name: "정기권안내",
    component: () => import("@/views/M6_popup/SeasonTicketPopup.vue"),
  },
  {
    path: "/endPopup",
    name: "정기권종료안내",
    component: () => import("@/views/M6_popup/SeasonTicketEndPopup.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
