<template>
    <div class="submenu">
        <div class="submenu-title"> {{ name }}</div>
        <div class="submenu-info">

            <div class="list-box box1">
                <div class="submenu-list" :class="{ active: this.$store.state.menuActive1 }" @click="AddcarRouter">
                    <span class="text">차량등록 현황</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
                <div class="submenu-menu">
                    <div class="list" :class="{ active: this.$store.state.submenuActive4 }" @click="AddcarRouter"><a>차량 등록 관리</a></div>
                    <div class="list" :class="{ active: this.$store.state.submenuActive5 }" @click="DiscountCarRouter"><a>할인 등록 관리</a></div>
                </div>
            </div>
            <div class="list-box box2">
                <div class="submenu-list" :class="{ active: this.$store.state.submenuActive1 }" @click="UsepassRouter">
                    <span class="text">정기권등록 현황</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
                <div class="submenu-menu-season-ticket">
                    <div class="list" :class="{ active: this.$store.state.menuActive2 }" @click="UsepassRouter"><a>정기권 사용 내역</a></div>
                    <div class="list" :class="{ active: this.$store.state.menuActive3 }" @click="SignpassRouter"><a>정기권 신청 내역</a></div>
                    <div class="list" :class="{ active: this.$store.state.submenuActive6 }" @click="PaymentHistoryRouter"><a>정기권 구매 내역</a></div>
                </div>
            </div>
            <!-- <div class="list-box box3">
                <div class="submenu-list" :class="{ active: this.$store.state.submenuActive2 }" @click="EditmemberRouter">
                    <span class="text" >회원정보 수정</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div> -->
            <div class="list-box box3">
                <div class="submenu-list" :class="{ active: this.$store.state.submenuActive3 }" @click="LeavememberRouter">
                    <span class="text" >회원 탈퇴</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            name: "마이페이지"
        }
    },
    methods: {
        AddcarRouter() {
            this.$router.push('/Addcar').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = true;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        DiscountCarRouter() {
            this.$router.push('/DiscountCar').catch(() => { });
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = true;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        UsepassRouter() {
            this.$router.push('/Usepass').catch(()=>{});
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = true;
            this.$store.state.menuActive3 = false;
        },
        SignpassRouter() {
            this.$router.push('/Signpass').catch(()=>{});
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = true;
        },
        PaymentHistoryRouter() {
            this.$router.push('/Paymentpass').catch(()=>{});
            this.$store.state.submenuActive1 = true;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = true;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        EditmemberRouter() {
            this.$router.push('/Editmem').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = true;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        LeavememberRouter() {
            this.$router.push('/Leavemem').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = true;
            this.$store.state.submenuActive4 = false;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.submenuActive7 = false;
            this.$store.state.submenuActive8 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
        },
        
    }
}
</script>
<style lang="scss" scoped>
</style>