<template>
    <div class="submenu">
        <div class="submenu-title"> {{ name }}</div>
        <div class="submenu-info">

            <div class="list-box box1" :class="{ active: this.$store.state.menuActive1 }" @click="NoticeRouter">
                <div class="submenu-list">
                    <span class="text">공지사항</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div>
            <div class="list-box box2" :class="{ active: this.$store.state.menuActive2 }" @click="faqRouter">
                <div class="submenu-list">
                    <span class="text">자주하는 질문</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div>
            <div class="list-box box3" :class="{ active: this.$store.state.menuActive3 }" @click="ServiceRouter">
                <div class="submenu-list">
                    <span class="text" >문의사항</span>
                    <span class="list-arrow"> &#x276F;</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            name: "고객지원"
        }
    },
    methods: {
        NoticeRouter() {
            this.$router.push('/Notice').catch(()=>{});
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive1 = true;
        },
        faqRouter() {
            this.$router.push('/FAQ').catch(()=>{});
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.menuActive2 = true;
        },
        ServiceRouter() {
            this.$router.push('/Service').catch(()=>{});
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive1 = false;
            this.$store.state.menuActive3 = true;
        },
        
    }
}
</script>
<style lang="scss" scoped>
</style>