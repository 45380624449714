import axios from "axios";

const request = axios.create({
  // baseURL: process.env.VUE_APP_BUILD_URL,
  // baseURL: "http://localhost:8080/api/user",
  // baseURL: "http://219.248.246.69:9000/api/user",
  baseURL: "https://smartparking.gwanakgongdan.or.kr:9000/api/user",
});

request.interceptors.request.use(async (config) => {
  if (!config.headers["Authorization"]) {
    if (localStorage.getItem("accessTokenUser") && localStorage.getItem("accessTokenUser").length > 0) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem("accessTokenUser")}`;
    }
  }
  return config;
})

export default request;
