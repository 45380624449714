<template>
    <div class="nav">
        <ul>
            <li @click="MainRouter">로그아웃</li>
            <li @click="AddcarRouter">마이페이지</li>
            <li @click="SitemapRouter">사이트맵</li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isLogin: this.$store.state.isLogin,
        }
    },
    mounted() {
    },
    methods: {
        MainRouter() {
            if (this.$store.state.isLogin === true) {
                this.$store.state.isLogin = false
                if (localStorage.getItem('accessTokenUser')) {
                    localStorage.removeItem('accessTokenUser')
                } 

                if (localStorage.getItem('accessTokenUserIndex')) {
                    localStorage.removeItem('accessTokenUserIndex')
                }

                this.$store.state.userName = null

                this.$store.state.isLogin = false

                alert('로그아웃되었습니다.')

                this.$store.state.isAdmin = false
            }
            this.$router.push('/MainPage').catch(()=>{});
            // window.location.href = 'https://www.gwanakgongdan.or.kr/fmcs/126?referer=https%3A%2F%2Fwww.gwanakgongdan.or.kr%2Ffmcs%2F117'
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;

            this.$store.state.isLogin = false
        },
        AddcarRouter() {
            this.$router.push('/Addcar').catch(()=>{});
            this.$store.state.submenuActive1 = false;
            this.$store.state.submenuActive2 = false;
            this.$store.state.submenuActive3 = false;
            this.$store.state.submenuActive4 = true;
            this.$store.state.submenuActive5 = false;
            this.$store.state.submenuActive6 = false;
            this.$store.state.menuActive1 = true;
            this.$store.state.menuActive2 = false;
            this.$store.state.menuActive3 = false;
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
        SitemapRouter() {
            this.$router.push('/Sitemap').catch(()=>{});
            this.$store.state.lineActive1 = false;
            this.$store.state.lineActive2 = false;
            this.$store.state.lineActive3 = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    ul {
        height: 100%;
        display: flex;
        color: white;
        font-family: 'NotoSansKR_Bold';
        font-size: 18px;
        align-items: center;
        box-sizing: border-box;

        li {
            box-sizing: border-box;
            cursor: pointer;
        }

        a {
            box-sizing: border-box;
            cursor: pointer;
            color: white;
            font-family: 'NotoSansKR_Bold';
            font-size: 18px;
            align-items: center;
            box-sizing: border-box;
            text-decoration: none;
        }

        li::after {
            margin: 0 0.5vw;
            content: "|";
        }

        a::after {
            margin: 0 0.5vw;
            content: "|";
        }

        li:last-child::after {
            margin: 0 0.1vw;
            content: "";
        }

        a:last-child::after {
            margin: 0 0.1vw;
            content: "";
        }

        li:hover {
            color: skyblue;
        }

        a:hover {
            color: skyblue;
        }

        li:hover::after {
            color: white;
        }

        a:hover::after {
            color: white;
        }
    }
}</style>